import React, { useState, useEffect } from 'react';
import { getCompany, isAuth } from '../actions/auth'
import Module from './core/Module'
import GraphPanel from './graph/GraphPanel';
import moment from 'moment';
import VisibilitySensor from 'react-visibility-sensor';
import { Grid, Button, ToggleButton, ToggleButtonGroup, Typography,  } from '@mui/material';
import { BarChart } from '@mui/icons-material';
import { isFeatureEnabled, Feature } from '../helpers/featureFlagHelpers';

interface TabPanelProps {
    children: JSX.Element;
    index: string | number;
    value: string | number;
}

export const TabPanel = ({children, index, value}:TabPanelProps) => {

    if(index !== value){
        return <></>;
    }
    return (
        children
    )
}


const TabPanelNativa = ({ user }) => {
    const [activeTab, setActiveTab] = useState<string>("gestion");
    const mediaMatch = window.matchMedia('(max-width: 576px)');
    const [matches, setMatches] = useState(mediaMatch.matches);
    const [type, setType] = useState("normal");
    const [show, setShow] = useState(false)

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    });

    useEffect(() => {
        if (user && isAuth() && isAuth().group._id == '6148aef6db4c26b1e1a6d69a') setType('facturacion')
    }, [user])

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    let start_add = []

    if(!show) return (
        <VisibilitySensor
            onChange={(isVisible) => setShow(isVisible)}
        >
            <Module>
                <Grid container direction={"row"} justifyContent="center" width={'100%'} height="150px">
                    <Grid item justifyContent={"center"} alignSelf={'center'}>
                        <Button onClick={() => setShow(true)} color={"primary"} variant="outlined" startIcon={<BarChart />}>Mostrar gráficas</Button>
                    </Grid>
                </Grid>
            </Module>
        </VisibilitySensor>
    )

    return (
        <Module title={'Gráficas'} action={
            <Grid sx={{display: {xs: 'none', sm: 'inherit'}}}>
            <ToggleButtonGroup value={activeTab} exclusive>
                <ToggleButton value={'gestion'} onClick={() => toggle('gestion')} sx={{
                    py: 0.25,
                    color: activeTab == 'gestion' ? "#fff!important" : '#0080ff!important',
                    borderColor: "#0080ff!important",
                    background: activeTab == 'gestion' ? "#0080ff!important" : "#fff!important",
                }}>
                    Gestion
                </ToggleButton>
                <ToggleButton value={'ventas'} onClick={() => toggle('ventas')} sx={{
                    py: 0.25,
                    color: activeTab == 'ventas' ? "#fff!important" : '#0080ff!important',
                    borderColor: "#0080ff!important",
                    background: activeTab == 'ventas' ? "#0080ff!important" : "#fff!important",
                }}>
                    Ventas
                </ToggleButton>

                <ToggleButton value={'cierres'} onClick={() => toggle('cierres')} sx={{
                    py: 0.25,
                    color: activeTab == 'cierres' ? "#fff!important" : '#0080ff!important',
                    borderColor: "#0080ff!important",
                    background: activeTab == 'cierres' ? "#0080ff!important" : "#fff!important",
                }}>
                    Cierres
                </ToggleButton>
            </ToggleButtonGroup>
            </Grid>
        }>
            <Grid sx={{display: {xs: 'inherit', sm: 'none'}, pb: 1}}>

            <Grid item mb={0.25}>
                <Typography fontSize={14} color="#666666">Panel</Typography>
            </Grid>
            <ToggleButtonGroup value={activeTab} exclusive>
                <ToggleButton value={'gestion'} onClick={() => toggle(0)} sx={{
                    py: 0.25,
                    color: activeTab == 'gestion' ? "#fff!important" : '#0080ff!important',
                    borderColor: "#0080ff!important",
                    background: activeTab == 'gestion' ? "#0080ff!important" : "#fff!important",
                }}>
                    Gestion
                </ToggleButton>
                <ToggleButton value={'ventas'} onClick={() => toggle(1)} sx={{
                    py: 0.25,
                    color: activeTab == 'ventas' ? "#fff!important" : '#0080ff!important',
                    borderColor: "#0080ff!important",
                    background: activeTab == 'ventas' ? "#0080ff!important" : "#fff!important",
                }}>
                    Ventas
                </ToggleButton>

                <ToggleButton value={'cierres'} onClick={() => toggle(2)} sx={{
                    py: 0.25,
                    color: activeTab == 'cierres' ? "#fff!important" : '#0080ff!important',
                    borderColor: "#0080ff!important",
                    background: activeTab == 'cierres' ? "#0080ff!important" : "#fff!important",
                }}>
                    Cierres
                </ToggleButton>
            </ToggleButtonGroup>
            </Grid>
                <TabPanel value={activeTab} index={"gestion"}>
                    <Grid xs={12}>
                            {getCompany() &&
                            <GraphPanel title='Gestion'
                                useAsUser={user}
                                graphs={[
                                ...start_add,
                                {
                                    title: 'Contactos tomados',
                                    source: 'contacts',
                                    group: 'default',
                                    groupby: 'createdAt',
                                    colorby: 'sourceProspect',
                                    sumtype: 'count',
                                    sumfield: '_id',
                                    userkey: 'firstAgent.email',
                                    userAs: 'email',
                                    fillextralabels: true,
                                    ignoreunusedlabels: true,
                                    labels: getCompany().origins.map((or) => ({key: or.code, name: or.name})),
                                    match: {userMade: {$eq: false}},
                                },
                                {
                                title: 'Contactos creados',
                                source: 'contacts',
                                group: 'default',
                                groupby: 'createdAt',
                                colorby: 'sourceProspect', // Falta agregar source correcta
                                sumtype: 'count',
                                sumfield: '_id',
                                userkey: 'firstAgent.email',
                                userAs: 'email',
                                fillextralabels: true,
                                ignoreunusedlabels: true,
                                labels: getCompany().origins ? getCompany().origins.map((ar) => ({key: ar.code, name: ar.name})) : [],
                                match: {userMade: {$eq: true}, "leads.providerKey": {$exists: true},},
                    
                            },
                            {
                                title: 'Seguimientos',
                                source: 'tracings',
                                group: 'default',
                                groupby: 'dateDate',
                                colorby: 'validity', // Falta agregar source correcta
                                sumtype: 'count',
                                sumfield: '_id',
                                colors: ["#7f7f7f","#FFD730" ,"#feaf1a","#ff4560","#00e395"],
                                // ignoreunusedlabels: true,
                                labels: [
                                {key: "not_client", name: "Sin cliente", data: []},
                                {key: "not_answer", name: "Sin respuesta", data: []},
                                {key: "not_time", name: "Sin tiempo", data: []},
                                {key: "not_record", name: "Sin registro", data: []},
                                {key: "valid", name: "Valido", data:[]},
                                ]
                                //match: {userMade:true} // Falta agregar mas a los filtros
                    
                            },
                                {
                                    title: 'Ventas',
                                    source: 'receipts',
                                    group: 'default',
                                    groupby: 'date_sale',
                                    colorby: 'status',
                                    sumtype: getCompany().groupType == 'facturacion' ? 'sum' : 'count',
                                    sumfield: getCompany().groupType == 'facturacion' ? 'value' : '_id',
                                    match: {sold:true}
                    
                                },
                                {
                                title: 'Ingresadas',
                                source: 'receipts',
                                group: 'default',
                                groupby: 'date',
                                colorby: 'status',
                                sumtype: getCompany().groupType == 'facturacion' ? 'sum' : 'count',
                                sumfield: getCompany().groupType == 'facturacion' ? 'value' : '_id',
                            },
                            
                            {
                                title: 'Ventas por canal',
                                source: 'receiptscontact',
                                group: 'default',
                                groupby: 'date_sale',
                                colorby: 'sourceProspect',
                                sumtype: getCompany().groupType == 'facturacion' ? 'sum' : 'count',
                                sumfield: getCompany().groupType == 'facturacion' ? 'value' : '_id',
                                match: {sold:true},
                                fillextralabels: true,
                                ignoreunusedlabels: true,
                                labels: getCompany().origins ? getCompany().origins.map((ar) => ({key: ar.code, name: ar.name})) : [],
                            },
                            
                            {
                            title: 'Cierres',
                            source: 'closes',
                            group: 'default',
                            groupby: 'createdAt',
                            colorby: 'to',
                            sumtype: 'count',
                            sumfield: '_id',
                            userkey: 'agent_email',
                            userAs: 'email',
                            match: {to: {$ne: null}},
                            fillextralabels: true,
                            ignoreunusedlabels: true,
                            labels: getCompany().archivingReasons ? getCompany().archivingReasons.map((ar) => ({key: ar.code, name: ar.name})) : [],
                        },
                    
                        {
                            title: 'Minutos al aire',
                            source: 'calls',
                            group: 'default',
                            groupby: 'timestampDate',
                            colorby: 'prospectName',
                            colorbyexist: true,
                            sumtype: 'sum',
                            sumfield: 'duration',
                            colors: ["#7f7f7f", "#008ffa"],
                            labels: [ {key: false, name: 'No es cliente'}, {key: true, name: 'Cliente'},],
                            mapValues: (number) => {
                            return typeof number == "number" ? Math.floor(number/60) : Math.floor(parseInt(number)/60);
                            }
                        },
                        {
                            title: 'Llamadas',
                            source: 'calls',
                            group: 'default',
                            groupby: 'timestampDate',
                            colorby: 'durationRange',
                            sumtype: 'count',
                            sumfield: '_id',
                            colors: ["#ff4560","#edbf33","#FFD730" ,"#bdcf32","#87bc45", "#00e395"],
                            labels: [
                            {key: "not_answer", name: "Sin respuesta", data: []},
                            {key: "lt_10", name: "0:00 a 0:10", data: []},
                            {key: "lt_35", name: "0:10 a 0:35", data: []},
                            {key: "lt_90", name: "0:35 a 1:30", data: []},
                            {key: "lt_180", name: "1:30 a 3:00", data: []},
                            {key: "ge_180", name: "Mayor a 3 min", data:[]},
                            ]
                            // match: {to: {$ne: null}}
                        },
                        ...(isFeatureEnabled(Feature.CHANNELS) ? [{
                            title: 'Chats redes',
                            source: 'chats',
                            group: 'default',
                            groupby: 'createdAt',
                            colorby: 'content.medium',
                            sumtype: 'count',
                            sumfield: '_id',
                            labels: [
                                {key: 'fb', name: 'Facebook'},
                                {key: 'ig', name: 'Instagram'}
                            ],
                            match: {"content.medium": {$ne: 'leads'}}
                        },
                        {
                            title: 'Chats Whatsapp',
                            source: 'chats-wpp',
                            group: 'default',
                            groupby: 'createdAt',
                            colorby: 'content.channelName',
                            sumtype: 'count',
                            sumfield: '_id',
                            nulltext: 'Whatsapp Personal'
                            // match: {"content.medium": {$ne: 'leads'}}
                        },
                    ] : []),
                        {
                        title: 'Atención de contactos',
                        source: 'contacts',
                        group: 'default',
                        groupby: 'createdAt',
                        colorby: 'stats.attention',
                        sumtype: 'count',
                        sumfield: '_id',
                        userkey: 'agent.email',
                        userAs: 'email',
                        fillextralabels: true,
                        labels: true ? [ 
                            
                            {key: 'not_actions', name: 'Sin acciónes'}, 
                            {key: 'chat', name: 'Chat'}, 
                            {key: 'call', name: 'Llamada'}, 
                            {key: 'call_chat', name: 'Llamada + Chat'}, 
                            {key: 'tracing', name: 'Seguimiento'}, 
                            {key: 'tracing_chat', name: 'Seguimiento + Chat'}, 
                        ] : [
                            {key: 'not_actions', name: 'Sin acciónes'}, 
                            {key: 'call', name: 'Llamada'}, 
                            {key: 'tracing', name: 'Seguimiento'}, 
                        ],
                        // optionallabels: isAuth() && isAuth().productType != "ventia_freelancer" ? [ 
                        // ] : [
                        //     {key: 'chat', name: 'Chat'}, 
                        //     {key: 'call_chat', name: 'Llamada + Chat'}, 
                        //     {key: 'tracing_chat', name: 'Seguimiento + Chat'}, 

                        // ],
                    },

                    {
                        title: 'Visitas',
                        source: 'visits',
                        group: 'default',
                        groupby: 'createdAt',
                        colorby: 'content.state',
                        sumtype: 'count',
                        sumfield: '_id',
                        userkey: 'agent',
                        userAs: 'id',
                        fillextralabels: true,
                        ignoreunusedlabels: true,
                        labels: getCompany().visitResults ? getCompany().visitResults.map((ar) => ({key: ar.code, name: ar.name})) : [],
            
                    },
                    
                    {
                        title: 'Notas Whatsapp',
                        source: 'whatsapp-notes',
                        group: 'default',
                        groupby: 'createdAt',
                        // colorby: 'status',
                        nulltext: "Whatsapp",
                        sumtype: 'count',
                        userkey: 'agent',
                        userAs: 'id',
                        sumfield: '_id',
                    },
                                ]}
                            />}
                    </Grid>
                </TabPanel>
                <TabPanel value={activeTab} index={"ventas"}>
                    <Grid xs={12}>
                            {getCompany() && activeTab == "ventas" &&
                            <GraphPanel title='Ventas'
                                useAsUser={user}
                                defaultGroupType={"total"}
                                initialDateFilter={"last_3_month"}
                                filters={[
                                    {
                                        title: "Origen",
                                        key: 'origin',
                                        keysModel: {
                                            receiptscontact: 'sourceProspect'
                                        },
                                        options: [
                                            ...getCompany().origins.map((o) => [o.code, o.name])
                                        ]
                                    },
                                    {
                                        title: 'Atención',
                                        key: 'atention',
                                        keysModel: {
                                            receiptscontact: 'prospect.stats.attention'
                                        },
                                        options:[
                                            ['not_actions', 'Sin acciónes'], 
                                            ['chat', 'Chat'], 
                                            ['call', 'Llamada'], 
                                            ['call_chat', 'Llamada + Chat'], 
                                            ['tracing', 'Seguimiento'], 
                                            ['tracing_chat', 'Seguimiento + Chat'], 
                                        ]
                                    }
                                ]}
                                initialDateValue={
                                    {
                                        gt: moment().add(-2, 'month').startOf('month').startOf('day').add(-1, 'd').toISOString(),
                                    }
                                }
                                graphs={[
                                
                                {
                                    title: 'Ventas por canal',
                                    id: 'ventas_ventas',
                                    source: 'receiptscontact',
                                    group: 'default',
                                    groupby: 'date_sale',
                                    colorby: 'sourceProspect',
                                    sumtype: getCompany().groupType == 'facturacion' ? 'sum' : 'count',
                                    sumfield: getCompany().groupType == 'facturacion' ? 'value' : '_id',
                                    nulltext: "Sin contacto",
                                    match: {sold:true},
                                    fillextralabels: true,
                                    ignoreunusedlabels: true,
                                    labels: getCompany().origins ? getCompany().origins.map((ar) => ({key: ar.code, name: ar.name})) : [],
                                },
                                {
                                    title: 'Ventas por dia desde ingreso',
                                    id: 'ventas_por_dia_ingreso',
                                    source: 'receiptscontact',
                                    group: 'default',
                                    groupby: 'date_sale',
                                    colorby: 'dia_ingreso',
                                    sumtype: getCompany().groupType == 'facturacion' ? 'sum' : 'count',
                                    sumfield: getCompany().groupType == 'facturacion' ? 'value' : '_id',
                                    match: {sold:true},
                                    nulltext: "Sin contacto",
                                    labels: [
                                        {key: "not_prospect", name: "Sin contacto"},
                                        {key: "less_1_day", name: "0-1 dia"},
                                        {key: "less_3_days", name: "2-3 dias"},
                                        {key: "less_7_days", name: "4-7 dias"},
                                        {key: "less_15_days", name: "8-15 dias"},
                                        {key: "less_30_days", name: "16-30 dias"},
                                        {key: "less_60_days", name: "31-60 dias"},
                                        {key: "more_60_days", name: "61 dias o mas"},
                                    ]
                                },
                                {
                                    title: 'Ventas por atención',
                                    id: 'ventas_attention',
                                    source: 'receiptscontact',
                                    group: 'default',
                                    groupby: 'date_sale',
                                    colorby: 'prospect.stats.attention',
                                    sumtype: getCompany().groupType == 'facturacion' ? 'sum' : 'count',
                                    sumfield: getCompany().groupType == 'facturacion' ? 'value' : '_id',
                                    nulltext: "Sin contacto",
                                    match: {sold:true},
                                    fillextralabels: true,
                                    ignoreunusedlabels: true,
                                    labels: true ? [ 
                                
                                        {key: 'not_actions', name: 'Sin acciónes'}, 
                                        {key: 'chat', name: 'Chat'}, 
                                        {key: 'call', name: 'Llamada'}, 
                                        {key: 'call_chat', name: 'Llamada + Chat'}, 
                                        {key: 'tracing', name: 'Seguimiento'}, 
                                        {key: 'tracing_chat', name: 'Seguimiento + Chat'}, 
                                    ] : [
                                        {key: 'not_actions', name: 'Sin acciónes'}, 
                                        {key: 'call', name: 'Llamada'}, 
                                        {key: 'tracing', name: 'Seguimiento'}, 
                                    ],                                        
                                },
                                {
                                    title: 'Ventas por tipo',
                                    source: 'receiptscontact',
                                    group: 'default',
                                    groupby: 'date_sale',
                                    nulltext: "Sin tipo",
                                    colorby: 'type',
                                    sumtype: getCompany().groupType == 'facturacion' ? 'sum' : 'count',
                                    sumfield: getCompany().groupType == 'facturacion' ? 'value' : '_id',
                                    match: {sold:true}
                    
                                },

                                {
                                    title: 'Ventas por estado/departamento',
                                    id: 'ventas_estado',
                                    source: 'receiptscontact',
                                    group: 'default',
                                    groupby: 'date_sale',
                                    nulltext: "Sin datos",
                                    colorby: 'state',
                                    sumtype: getCompany().groupType == 'facturacion' ? 'sum' : 'count',
                                    sumfield: getCompany().groupType == 'facturacion' ? 'value' : '_id',
                                    match: {sold:true}
                    
                                },
                                {
                                    title: 'Ventas por producto',
                                    id: 'ventas_producto',
                                    source: 'receiptscontact',
                                    group: 'default',
                                    groupby: 'date_sale',
                                    colorby: 'product',
                                    nulltext: "Sin producto",
                                    sumtype: getCompany().groupType == 'facturacion' ? 'sum' : 'count',
                                    sumfield: getCompany().groupType == 'facturacion' ? 'value' : '_id',
                                    match: {sold:true}
                    
                                },
                                {
                                    title: 'Ventas por categoria',
                                    id: 'ventas_categoria',
                                    source: 'receiptscontact',
                                    group: 'default',
                                    groupby: 'date_sale',
                                    colorby: 'category',
                                    nulltext: "Sin categoria",
                                    sumtype: getCompany().groupType == 'facturacion' ? 'sum' : 'count',
                                    sumfield: getCompany().groupType == 'facturacion' ? 'value' : '_id',
                                    match: {sold:true}
                    
                                },

                                

                            
                            
                            
                                ]}
                            />}
                    </Grid>
                </TabPanel>
                <TabPanel value={activeTab} index={"cierres"}>
                    <Grid xs={12}>
                            {getCompany() && activeTab == "cierres" &&
                            <GraphPanel title='Cierres'
                                useAsUser={user}
                                defaultGroupType={"monthyear"}
                                initialDateFilter={"last_3_month"}
                                filters={[
                                    {
                                        title: "Cierre",
                                        key: 'to',
                                        keysModel: {
                                            closescontact: 'to'
                                        },
                                        options: [
                                            ...getCompany().archivingReasons.map((o) => [o.code, o.name])
                                        ]
                                    },
                                    {
                                        title: "Origen",
                                        key: 'closescontact',
                                        keysModel: {
                                            closescontact: 'closescontact'
                                        },
                                        options: [
                                            ...getCompany().origins.map((o) => [o.code, o.name])
                                        ]
                                    },
                                    {
                                        title: 'Atención',
                                        key: 'atention',
                                        keysModel: {
                                            closescontact: 'prospect.stats.attention'
                                        },
                                        options:[
                                            ['not_actions', 'Sin acciónes'], 
                                            ['chat', 'Chat'], 
                                            ['call', 'Llamada'], 
                                            ['call_chat', 'Llamada + Chat'], 
                                            ['tracing', 'Seguimiento'], 
                                            ['tracing_chat', 'Seguimiento + Chat'], 
                                        ]
                                    }
                                ]}
                                initialDateValue={
                                    {
                                        gt: moment().add(-2, 'month').startOf('month').startOf('day').add(-1, 'd').toISOString(),
                                    }
                                }
                                graphs={[
                                
                                {
                                    title: 'Cierres',
                                    id: 'contacts_origin',
                                    source: 'closescontact',
                                    group: 'default',
                                    groupby: 'createdAt',
                                    colorby: 'to',
                                    sumtype: 'count',
                                    sumfield: '_id',
                                    userkey: 'agent_email',
                                    userAs: 'email',
                                    nulltext: "Sin cerrar",
                                    fillextralabels: true,
                                    ignoreunusedlabels: true,
                                    labels: getCompany().archivingReasons ? getCompany().archivingReasons.map((ar) => ({key: ar.code, name: ar.name})) : [],
                                },
                                {
                                    title: 'Cierres por canal contacto',
                                    id: 'contacts_cierres_canal',
                                    source: 'closescontact',
                                    group: 'default',
                                    groupby: 'createdAt',
                                    colorby: 'sourceProspect',
                                    sumtype: 'count',
                                    sumfield: '_id',
                                    userkey: 'agent_email',
                                    userAs: 'email',
                                    nulltext: "Sin canal",
                                    fillextralabels: true,
                                    ignoreunusedlabels: true,
                                    labels: getCompany().origins ? getCompany().origins.map((ar) => ({key: ar.code, name: ar.name})) : [],
                                },
                                {
                                    title: 'Cierres por atención a contacto',
                                    id: 'cierres_attention_group',
                                    source: 'closescontact',
                                    group: 'default',
                                    groupby: 'createdAt',
                                    colorby: 'prospect.stats.attention',
                                    sumtype: 'count',
                                    sumfield: '_id',
                                    nulltext: "Sin atención",
                                    userkey: 'agent_email',
                                    userAs: 'email',
                                    // match: {sold:true},
                                    fillextralabels: true,
                                    ignoreunusedlabels: true,
                                    labels: [
                                        {key: 'not_actions', name: 'Sin acciónes'}, 
                                        {key: 'chat', name: 'Chat'}, 
                                        {key: 'call', name: 'Llamada'}, 
                                        {key: 'call_chat', name: 'Llamada + Chat'}, 
                                        {key: 'tracing', name: 'Seguimiento'}, 
                                        {key: 'tracing_chat', name: 'Seguimiento + Chat'}, 
                                    ],
                                },
                                {
                                    title: 'Cierres por dia desde ingreso del contacto',
                                    id: 'cierres_por_dia_ingreso',
                                    source: 'closescontact',
                                    group: 'default',
                                    groupby: 'createdAt',
                                    colorby: 'dia_ingreso',
                                    sumtype: 'count',
                                    sumfield: '_id',
                                    nulltext: "Sin contacto",
                                    labels: [
                                        {key: "not_prospect", name: "Sin contacto"},
                                        {key: "less_1_day", name: "0-1 dia"},
                                        {key: "less_3_days", name: "2-3 dias"},
                                        {key: "less_7_days", name: "4-7 dias"},
                                        {key: "less_15_days", name: "8-15 dias"},
                                        {key: "less_30_days", name: "16-30 dias"},
                                        {key: "less_60_days", name: "31-60 dias"},
                                        {key: "more_60_days", name: "61 dias o mas"},
                                    ]
                                },
                                ]}
                            />}
                    </Grid>
                </TabPanel>
        </Module>
    );
}


export default TabPanelNativa;