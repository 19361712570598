import React, { useState } from "react";
import { Avatar, Box, Chip, Theme } from "@mui/material";
import moment from "moment";
import { SxProps } from "@mui/system";
import { InstanceUser } from "../../interfaces/Interface";
import { Chat, Visibility, VisibilityOff } from "@mui/icons-material";

interface ChipAvatar {
  userChip: InstanceUser;
  size?: "small" | "medium" | "large";
  seen?: boolean;
  commented?: boolean;
}

const ChipAvatar = ({ userChip, size, seen, commented }: ChipAvatar) =>
  size == "small" ? (
    <Chip
      sx={{ height: "20px" }}
      label={
        <>
          {seen ? (
            commented ? (
              <Chat sx={{ fontSize: "14px", mr: "5px" }} />
            ) : (
              <Visibility sx={{ fontSize: "14px", mr: "5px" }} />
            )
          ) : (
            <VisibilityOff sx={{ fontSize: "14px", mr: "5px" }} />
          )}
          {`${userChip.name} ${userChip.lastname}`}
        </>
      }
    />
  ) : size == "medium" ? (
    <Chip
      avatar={
        <Avatar sx={{ bgcolor: `#0080ff`, color: `#fff !important` }}>
          {userChip.name[0]}
          {userChip.lastname[0]}
        </Avatar>
      }
      label={`${userChip.name} ${userChip.lastname}`}
    />
  ) : (
    <Chip
      avatar={
        <Avatar sx={{ bgcolor: `#0080ff`, color: `#fff !important` }}>
          {userChip.name[0]}
          {userChip.lastname[0]}
        </Avatar>
      }
      label={`${userChip.name} ${userChip.lastname}`}
    />
  );

export default ChipAvatar;
