
import { AttachMoney, Call, DateRange, Description, Info, InfoOutlined, Login, MeetingRoom, People, Person, QuestionMark, Schedule, SentimentDissatisfied, SentimentSatisfied, SentimentSatisfiedAlt, SentimentVeryDissatisfied, SentimentVerySatisfied, Smartphone } from "@mui/icons-material"

interface IconActionInterface {
    name: string
}

export const IconAction = ({name}: IconActionInterface) => {
    let sxIcon = {fontSize: 16}
    switch(name){

        case 'phone':
            return <Call sx={sxIcon} />
        case 'contacts':
            return <People sx={sxIcon}/>
        case 'login':
            return <Login sx={sxIcon}/>
        case 'usage':
            return <Smartphone sx={sxIcon}/>
        case 'reminder':
            return <Schedule sx={sxIcon}/>
        case 'note':
            return <Description sx={sxIcon}/>
        case 'sale':
            return <AttachMoney sx={sxIcon}/>
        case 'visit':
            return <MeetingRoom sx={sxIcon}/>
        default:
            return <QuestionMark sx={sxIcon} />
    }

}