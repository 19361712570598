import { useEffect, useMemo } from "react";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "../ui/hover-card";
import { PhoneIncoming } from "lucide-react";
import moment from "moment";
import { TableCell, TableRow } from "../ui/table";
import { columnsSellers } from "./columnsSellers";

export function ItemUserCell({ user, handleSelectedUser, selectedSeller }) {
    const { name, lastname, goal } = user;
    const nameUser = useMemo(() => `${name} ${lastname}`, [name, lastname]);
    const dataCell = useMemo(() => [
        {
            field: 'contacts',
            fieldValue: 'contactsValue'
        },
        {
            field: 'tracing',
            fieldValue: 'tracingValue',
            children: goal.goal && goal.lastCall?.updatedAt && <HoverCard openDelay={0.3} closeDelay={0.3}>
                <HoverCardTrigger asChild style={{ color: goal.lastCall?.color }}>
                    <button>
                        <PhoneIncoming size={13} />
                    </button>
                </HoverCardTrigger>
                <HoverCardContent className="w-auto py-2">
                    <p className="text-sm" style={{ color: goal.lastCall?.color }}>{moment(goal.lastCall?.updatedAt).format('L LT').toString()}</p>
                </HoverCardContent>
            </HoverCard>
        },
        {
            field: 'sale',
            fieldValue: 'saleValue'
        }
    ], [goal])

    return (
        <TableRow
            className={`hover:bg-slate-50  px-4 justify-center cursor-pointer ${selectedSeller?._id === user?._id ? 'bg-slate-50' : ''} flex items-center text-[14px] gap-2`} onClick={() => handleSelectedUser(user)}
            style={{
                color: goal?.color ?? "#0d0d0d"
            }}>
            <TableCell colSpan={columnsSellers.length} className="h-7 flex-[2] items-center flex max-h-7 min-h-7 relative p-0">
                <span>
                    {nameUser.length > 15 ? nameUser.slice(0, 15).concat("...") : nameUser}
                </span>
            </TableCell>
            {goal.goal && (
                <>
                    {dataCell.map((data) => (
                        <ItemSummaryGroup expected={data.field} goal={goal} goalValue={`${data.fieldValue}`} children={data.children} />
                    ))}
                </>
            )}
        </TableRow>
    );
}

function ItemSummaryGroup({ goal, expected, goalValue, children = <></> }) {
    return <TableCell
        style={{ color: goal.goal[0][`color${expected}`] }}
        className={`h-7 font-light flex items-center gap-[4px] max-h-7 flex-1 min-h-7 relative p-0`}>
        {`${goal.goal[0]?.[goalValue] ?? '0'}/${goal.goal[0]?.[expected] ?? '0'}`}
        {children}
    </TableCell>
}

