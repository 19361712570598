import fetch from 'isomorphic-fetch'
import {getCookie} from './auth'
var API = process.env.API


export const getLast = () => {
    return fetch(`${API}/calls/last`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const getLastGroup = () => {
    return fetch(`${API}/calls/lastgroup`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const getLastGroupFast = () => {
    return fetch(`${API}/calls/lastgroupfast`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const uploadGroup = (calls) => {
    return fetch(`${API}/calls/manual`,{
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        },
        body: JSON.stringify(calls)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}