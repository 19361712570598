import fetch from 'isomorphic-fetch'
import {getCookie} from './auth'
var API = process.env.API


export const createInstance = (instance) => {
    return fetch(`${API}/instances`,{
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        },
        body: JSON.stringify(instance)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const createEvaluation = (evaluation) => {
    return fetch(`${API}/evaluations`,{
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        },
        body: JSON.stringify(evaluation)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const comment = (id, comment) => {
    return fetch(`${API}/instances/${id}/comment`,{
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        },
        body: JSON.stringify(comment)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const list = () => {
    return fetch(`${API}/instances`,{
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const listAll = (groupFilter) => {
    return fetch(`${API}/instances/all?${groupFilter ? `group=${groupFilter}` : ''}`,{
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const listUser = (userId) => {
    return fetch(`${API}/instances/from/${userId}`,{
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const get = (id) => {
    return fetch(`${API}/instances/${id}`,{
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const getUsersInstanceList = () => {
    return fetch(`${API}/instances/userlist`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}
