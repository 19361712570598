import { Close } from "@mui/icons-material"
import { Grid, IconButton, Modal, SxProps } from "@mui/material"
import { useMemo } from "react"


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    background: '#f7f9fb',
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
    pt: 2,
}

const modalSizeNormal = {
    maxWidth: '400px',
    maxHeight: '600px',
}


const modalSizeLarge = {
    maxWidth: '1000px',
    maxHeight: '600px',
}

interface AdvancedModalInterface {
    open: boolean;
    handleClose: () => void,
    children: JSX.Element | JSX.Element[],
    title: string | JSX.Element,
    size?: 'normal' | 'large',
    expandMax?: boolean,
    sxContainer?: SxProps,
    sxModal?: SxProps,
}

const AdvancedModal = ({open, handleClose, children, title, size = 'normal', expandMax, sxContainer, sxModal}:AdvancedModalInterface) => {

    const styleSX:SxProps = useMemo(() => {
        let default_style:SxProps = modalStyle
        if(size == 'large'){
            default_style = {...default_style, ...modalSizeLarge}
        }else{
            default_style = {...default_style, ...modalSizeNormal}
        }

        if(expandMax) default_style = {...default_style, width: '100vw', height: '100vh'}

        return default_style

    }, [size, expandMax])


    return <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Grid container sx={{...styleSX, ...sxModal}}>
            <Grid container item justifyContent="space-between" width="100%" direction={"row"} mb={3}>
                <Grid item my={0.5} fontSize={'22px'}>{title}</Grid>
                <Grid item>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container sx={{width: '100%', ...sxContainer}}>
                {children}
            </Grid>
        </Grid>
    </Modal>
}

export default AdvancedModal