import moment from "moment"

moment.locale()

let fecha = moment()
const startOptions:ApexCharts.ApexOptions =  {
    chart: {
      type: 'bar' as 'bar',
      width: 100,
      height: 35,
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '80%'
      }
    },
    labels: new Array(7).fill(0).map((_, i) => moment().add('day', i*-1).format('L')).reverse(),
    xaxis: {
      categories: new Array(7).fill(0).map((_, i) => moment().add('day', i*-1).format('L')).reverse()
    },
    tooltip: {
      fixed: {
        enabled: false
      },
      x: {
        show: true
      },
      y: {
        // title: {
        //   formatter: function (v) {
        //     return
        //   }
        // }
      },
      marker: {
        show: false
      }
    }
}


export default startOptions