import { getLastGroupFast } from "@/actions/call";
import { calculateBusinessDays, calculateGoal, getAllGoalsInformation, getColorCall, getGroupList, summaryGanerate } from "@/components/StatusCard/goalHelpers";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Card, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Command, CommandGroup, CommandItem, CommandList } from "@/components/ui/command";
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import _ from "lodash";
import { AlertCircle, BarChart3, CalendarDays, Check, CheckCircle, ChevronDown, Clock11, Clock9, ListFilter, Loader2, Search, User } from "lucide-react";
import moment from "moment";
import Link from "next/link";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { connect, useDispatch } from "react-redux";
import { getWeekGoal } from "../../actions/comercial/metas";
import { setSelectedUser, setUsersGroup, updateColorsUsersGroup } from "../../redux/actions/main";
import { Badge } from "../ui/badge";
import { BadgeGoal } from "../ui/badgeGoal";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { Table, TableBody, TableCell } from "../ui/table";
import GraphicsArea from "./GraphicsArea";
import { ItemUserCell } from "./ItemUserCell";
import { columnsSellers } from "./columnsSellers";
import { IGoalInfo, IGoalStateKey, STATES_GOAL, colorsFilter } from "./interface";

const fieldOfGoalsToVerify = [['contacts', 'contactsValue'], ['tracing', 'tracingValue'], ['sale', 'saleValue']]
const defaultvaluesInfoGoals: IGoalInfo = { lates: 0, veryLates: 0, upToDate: 0, complete: 0, color: "", days: "" }

const StatusCard = ({ groupFilter, user, usersGroup }) => {
    const [openDialogSellers, setOpenDialogSellers] = useState<boolean>(false);
    const [allUsers, setAllUser] = useState<Array<any>>([])
    const [goalInformation, setGoalInformation] = useState<IGoalInfo>(defaultvaluesInfoGoals);
    const [selectedSeller, setSelectedSeller] = useState<any>(null)
    const [keywordSearch, setKeywordSearch] = useState<string>('')
    const [dateGoal, setDateGoal] = useState({ end: '', start: '' })
    const [filter, setFilter] = useState<string>('')
    const [selection, setSelection] = useState<'today' | 'yesterday'>('today')

    const dispatch = useDispatch();
    const getAllGoals = useCallback(() => getWeekGoal("calculate", user ?? "all", "now", undefined, selection == 'yesterday'), [selection, user])
    const generateColorsList = (listGoalsToChangeColor) => dispatch(updateColorsUsersGroup(listGoalsToChangeColor))

    const useGetLastCalls = useQuery(['all-called-sellers', user], getLastGroupFast)
    const useGetAllSellers = useQuery(['all-sellers-groups', user, selection], getAllGoals)

    const allCalls = useMemo(() => useGetLastCalls.data ?? [], [useGetLastCalls.data])
    const currentDay = useMemo(() => moment().format('dddd'), [user])

    useEffect(() => { setSelectedSeller(null) }, [openDialogSellers])

    const getAllDataFromSellers = useMemo(() => {
        if (!useGetAllSellers.data) return []
        const groupsActivesFilter = groupFilter && usersGroup
            .filter(group => group.ancestors.includes(groupFilter) || group._id === groupFilter)

        const userActives = groupFilter && groupsActivesFilter.map(group => group.users)
            .flat()
            .filter(user => user.active)
            .map(user => user._id)
        const isYesterday = selection == 'yesterday'

        const allGoals = useGetAllSellers.data ? JSON.parse(JSON.stringify([...useGetAllSellers.data.goals])) : []

        const infoGoalToModify = { ...defaultvaluesInfoGoals }

        const addColorsGoal = allGoals.map((goal) => {
            const goalToModify = _.cloneDeep(goal);

            if (goalToModify.start) {
                if (dateGoal.start === '' && goal.end) {
                    setDateGoal({ end: goalToModify.end, start: goalToModify.start })
                }
                const startDate = moment(goalToModify.start).startOf('day');
                const endDate = moment(goalToModify.end).startOf('day');

                goalToModify.daysGoal = calculateBusinessDays(startDate, endDate);

                var now = moment().startOf('day');

                if (isYesterday) {
                    now = now.add(-1, 'days')
                }

                const daysUntilToday = calculateBusinessDays(startDate, now);
                const totalDays = goalToModify.daysGoal > 0 ? goalToModify.daysGoal : 1;

                infoGoalToModify.days = `${daysUntilToday}/${totalDays}`;
                goalToModify.days = daysUntilToday == 0 ? 1 : daysUntilToday;
            }

            if (goalToModify.goal) {
                const currentGoal = goalToModify.goal[0]
                const goalInfoArray: IGoalStateKey[] = [];

                fieldOfGoalsToVerify.forEach(([expected, value]) => {
                    const goalInfo = calculateGoal({
                        value: currentGoal[value],
                        expected: currentGoal[expected],
                    }, _.cloneDeep(goalToModify))

                    goalInfoArray.push({ ...goalInfo, key: expected })
                })

                const statesGoal = goalInfoArray.map(goalInfo => goalInfo.state);
                const isActiveGoal = groupFilter && userActives.includes(goal.user._id)

                if (statesGoal.includes(STATES_GOAL.VERYBAD)) {
                    groupFilter ? isActiveGoal && infoGoalToModify.veryLates++ : infoGoalToModify.veryLates++
                    goalToModify.color = '#d32f2f';
                } else if (statesGoal.includes(STATES_GOAL.BAD)) {
                    groupFilter ? isActiveGoal && infoGoalToModify.lates++ : infoGoalToModify.lates++
                    goalToModify.color = '#ED6C02';
                } else if (statesGoal.includes(STATES_GOAL.UPTODATE)) {
                    groupFilter ? isActiveGoal && infoGoalToModify.upToDate++ : infoGoalToModify.upToDate++
                    goalToModify.color = '#2e7d32';
                }

                if (goalInfoArray.every(metaInfo => metaInfo.complete)) {
                    groupFilter ? isActiveGoal && infoGoalToModify.complete++ : infoGoalToModify.complete++
                    goalToModify.color = '#2e7d32';
                }

                goalInfoArray.forEach(goal => currentGoal[`color${goal.key}`] = goal.color)
                goalInfoArray.forEach(goal => currentGoal[`expected${goal.key}`] = goal.expected)
                infoGoalToModify.color = infoGoalToModify.lates > 0 ? "#dc3545" : "#28a745";
            }

            return goalToModify
        })

        generateColorsList(addColorsGoal)

        const calls = allCalls.map((call) => ({
            ...call,
            color: getColorCall(call.updatedAt)
        }));

        calls
            .filter(call => !groupFilter || (call.user?.[0]?.group === groupFilter))
            .forEach(call => {
                const user = call.user?.[0];
                if (user) {
                    const matchingGoal = addColorsGoal.find(goal => goal.user._id === user._id);
                    if (matchingGoal) {
                        matchingGoal.lastCall = call;
                    }
                }
            });

        const listUsersToSearch: any = []
        const allData = (groupFilter ? groupsActivesFilter : usersGroup).map(group => getAllGoalsInformation(group, listUsersToSearch, addColorsGoal))

        setGoalInformation(infoGoalToModify)
        setAllUser(listUsersToSearch.flat())
        return allData
    }, [useGetAllSellers.data, allCalls, groupFilter, selection])

    const getAllSubGroups = useMemo(() => {
        if (!getAllDataFromSellers || getAllDataFromSellers.length === 0) return []

        let minLevel: number;

        getAllDataFromSellers.map((group) => {
            const ancestorsLength = group.ancestors.length;
            if (minLevel === undefined || minLevel > ancestorsLength) {
                minLevel = ancestorsLength;
            }
        });

        const newGroupStruct = getAllDataFromSellers
            .filter((group) => group.ancestors.length === minLevel)
            .map((group) => getGroupList(group, getAllDataFromSellers));

        return newGroupStruct;
    }, [getAllDataFromSellers, groupFilter]);

    const resultToSearchSeller: Array<any> = useMemo(() => {
        const usersFilter = allUsers.filter(user => _.get(user, 'active', true) && (user.name + ' ' + user.lastname)
            .toLocaleLowerCase()
            .includes(keywordSearch.toLocaleLowerCase()))

        if (filter !== '') return usersFilter.filter(user => user.goal.color === filter)
        return usersFilter
    }, [keywordSearch, filter])

    return (
        <>
            <Card
                className={`w-full border-none shadow-none ${getAllSubGroups.length > 0 ? 'cursor-pointer' : ''}`}
                onClick={() => getAllSubGroups.length > 0 ? setOpenDialogSellers(true) : null}
            >
                <CardHeader className="flex flex-row items-center justify-between">
                    {useGetAllSellers.isLoading ? <div className='w-full flex justify-center items-center'>
                        <Loader2 className="mr-2 h-8 w-8 animate-spin text-primary " />
                    </div>
                        : getAllSubGroups.length > 0
                            ? <>
                                <div className="flex gap-2">
                                    <div className="flex flex-col gap-1">
                                        <div className="flex items-center gap-2">
                                            <CardTitle
                                                className="text-[17px] space-x-2 flex items-center"
                                                style={{
                                                    color: goalInformation.lates > 0 && goalInformation.veryLates === 0
                                                        ? '#ec6b03'
                                                        : goalInformation.veryLates > 0
                                                            ? '#d3302e'
                                                            : '#2e7d32'
                                                }}>
                                                <span>
                                                    {goalInformation.lates > 0 || goalInformation.veryLates
                                                        ? <AlertCircle size={19} />
                                                        : <CheckCircle size={19} />}
                                                </span>
                                                <span>
                                                    {goalInformation.lates > 0 && goalInformation.veryLates === 0
                                                        && `${goalInformation.lates} vendedores están atrasados en el cierre hoy ${currentDay}`}
                                                    {goalInformation.veryLates > 0
                                                        && `${goalInformation.veryLates} vendedores están muy atrasados en el cierre de hoy ${currentDay}`}
                                                    {goalInformation.veryLates === 0 && goalInformation.lates === 0
                                                        && `Tus vendedores están al día en el cierre de hoy ${currentDay}`}
                                                </span>
                                            </CardTitle>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <div className="flex items-center flex-wrap gap-2 pl-[30px]">
                                                {goalInformation.veryLates > 0 && <BadgeGoal variant={'destructive'}>
                                                    <Clock11 size={13} />
                                                    Muy atrasados {goalInformation.veryLates}
                                                </BadgeGoal>}
                                                {goalInformation.lates > 0 && <BadgeGoal variant={'lates'}>
                                                    <Clock9 size={13} />
                                                    Atrasados {goalInformation.lates}
                                                </BadgeGoal>}
                                                {goalInformation.upToDate > 0 && <BadgeGoal variant={'success'}>
                                                    <Check size={13} />
                                                    Al día {goalInformation.upToDate}
                                                </BadgeGoal>}
                                                {goalInformation.days !== '' && <BadgeGoal>
                                                    <CalendarDays size={13} />
                                                    {goalInformation.days} días
                                                </BadgeGoal>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-slate-800">
                                    <ChevronDown size={16} />
                                </div>
                            </>
                            : <div className="flex gap-2">
                                <div className="text-primary">
                                    <BarChart3 size={25} />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <div className="flex items-center gap-2">
                                        <CardTitle
                                            className="text-[17px] space-x-2 flex items-center">
                                            No tienes metas subidas
                                        </CardTitle>
                                    </div>
                                    <CardDescription>
                                        Sube las metas dando{" "}
                                        <Link className="text-primary" href="https://panel.getventia.com/goals/upload"
                                        >
                                            click aquí
                                        </Link>
                                    </CardDescription>
                                </div>
                            </div>}
                </CardHeader>
            </Card>
            {/* -------------Start Dialog------------- */}
            <Dialog open={!!openDialogSellers} onOpenChange={setOpenDialogSellers}>
                <DialogContent
                    className="sm:max-w-[1070px] lg:min-h-[95vh] min-h-[100vh] p-0 m-0"
                    style={{ paddingBottom: 0, margin: 0 }}
                >
                    <div className="w-full h-full flex  items-center">
                        <div className="h-full flex-[4] pl-6 py-4 flex flex-col gap-2">
                            <DialogTitle>Rendimiento de vendedores</DialogTitle>
                            <div className="w-full flex items-center mt-1 justify-end">
                                <div className="flex relative gap-1 pr-2">
                                    <Select onValueChange={(value: "today") => setSelection(value)} value={selection}>
                                        <SelectTrigger className="w-[100px]">
                                            <SelectValue placeholder="Hoy" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="today">Hoy</SelectItem>
                                            <SelectItem value="yesterday">Ayer</SelectItem>
                                        </SelectContent>
                                    </Select>
                                    <form onSubmit={(e) => e.preventDefault()} className="h-[40px] py-[2px] m-0 flex items-center border border-input rounded-md">
                                        <Input
                                            autoComplete="off"
                                            placeholder="Buscar por vendedor..."
                                            onChange={(e) => setKeywordSearch(e.target.value)}
                                            value={keywordSearch}
                                            autoFocus
                                            className="border-none placeholder:text-xs focus-visible:ring-0 focus-visible:ring-offset-0"
                                        />
                                        <button className='text-primary mr-3' aria-label="search">
                                            <Search size={15} />
                                        </button>
                                    </form>
                                    <Popover>
                                        <PopoverTrigger asChild>
                                            <Button variant={'outline'} className="relative w-[40px] h-[40px]">
                                                {filter && <span className="w-[8px] h-[8px] rounded-full -top-[3px] -right-[3px] absolute bg-primary"></span>}
                                                <span>
                                                    <ListFilter size={17} />
                                                </span>
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent className="flex flex-col">
                                            <div className="space-y-2">
                                                <h4 className="font-semibold text-sm leading-none">Filtrado por color</h4>
                                            </div>
                                            <div className="flex flex-col gap-2 mt-3">
                                                <Command>
                                                    <CommandList className="h-max relative gap-1 flex flex-col">
                                                        <CommandGroup className="p-0">
                                                            {colorsFilter.map(color => {
                                                                const colorToUse: string = color.color
                                                                return <div onClick={() => setFilter(filter === colorToUse ? '' : colorToUse)} key={colorToUse}>
                                                                    <CommandItem className={`cursor-pointer space-x-2 ${filter === colorToUse ? 'bg-accent' : ''}`} >
                                                                        <div className={`w-[15px] h-[15px] rounded-full border-2 border-transparent shrink-0`}
                                                                            style={{ backgroundColor: colorToUse }}>
                                                                        </div>
                                                                        <span className="text-[15px]">{color.label}</span>
                                                                    </CommandItem>
                                                                </div>
                                                            })}
                                                        </CommandGroup>
                                                    </CommandList>
                                                </Command>
                                            </div>
                                        </PopoverContent>
                                    </Popover>
                                </div>
                            </div>
                            <div className="relative flex-grow">
                                {/* -------------Start Table------------- */}
                                <div className="w-full overflow-hidden h-full mb-[5px] min-h-[60vh] box-border relative mt-2">
                                    <div className="relative overflow-auto h-full scrollModifyTable">
                                        <Table className="absolute left-0 h-full w-full">
                                            <thead className="w-full">
                                                <tr className="flex-1 border-b px-3 w-full flex items-center">
                                                    {columnsSellers.map((header, inx) =>
                                                        header.isPlaceholder ? null : (
                                                            <td key={header.accessorKey} className={` h-12 flex items-center text-left align-middle ${inx == 0 ? 'flex-[2]' : 'flex-1'} font-medium text-muted-foreground`}>
                                                                {header.header}
                                                            </td>
                                                        )
                                                    )}
                                                </tr>
                                            </thead>
                                            <TableBody className="w-full absolute">
                                                <div className="w-full">
                                                    {keywordSearch.length > 0 || filter
                                                        ? resultToSearchSeller.length === 0
                                                            ? <TableCell className="h-20 text-center w-full">
                                                                Sin resultados
                                                            </TableCell>
                                                            : resultToSearchSeller.map(user => (
                                                                <ItemUserCell selectedSeller={selectedSeller} handleSelectedUser={(user) => setSelectedSeller(user)} user={user} key={user._id} />
                                                            ))
                                                        : getAllSubGroups.length > 0 && (
                                                            <>
                                                                <CellGroupAndUser
                                                                    selectedSeller={selectedSeller}
                                                                    handleSelectedUser={(user) => setSelectedSeller(user)}
                                                                    group={getAllSubGroups[0]}
                                                                    firstGroup={true}
                                                                    key={getAllSubGroups[0]._id}
                                                                />
                                                            </>
                                                        )}
                                                </div>
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                                {/* -------------End Table------------- */}
                            </div>
                        </div>
                        <GraphicsArea
                            selectedSeller={selectedSeller}
                            handleDeelectSeller={() => setSelectedSeller(null)} />
                    </div>
                </DialogContent>
            </Dialog>
            {/* -------------End Dialog------------- */}
        </>
    );
};

function CellGroupAndUser({ group, handleSelectedUser, selectedSeller, firstGroup }) {

    const sellersSummary = useMemo(() => {
        const summarySellers = summaryGanerate(group, { '#d32f2f': 0, '#ED6C02': 0, '#2e7d32': 0 })
        return summarySellers
    }, [group])

    const orderUsers = useMemo(() => {
        const colorOrder = { "#d32f2f": 0, "#ED6C02": 1, "#2e7d32": 2 };
        const usersSorted = group.users.slice().sort((a, b) => {
            const colorA = colorOrder[a.goal?.color];
            const colorB = colorOrder[b.goal?.color];
            return colorA - colorB;
        });
        return usersSorted;
    }, [group]);

    return (
        <div className="w-full">
            <div className="w-full relative p-0">
                <Accordion type="single" collapsible className="w-full bg-white pb-0" defaultValue={firstGroup ? group._id : ""} >
                    <AccordionItem
                        value={group._id}
                        autoFocus={false}
                    >
                        <AccordionTrigger className="hover:bg-slate-50 py-3 px-4 relative [&>svg]:absolute [&>svg]:right-4">
                            <tr className="flex justify-center items-center  w-full">
                                <TableCell className="h-6 flex-[2] flex items-center justify-start max-h-6 min-h-6 relative p-0 py-1">
                                    <div className="flex items-center justify-start gap-2">
                                        <h4
                                            className="text-[14px] flex items-center gap-[7px] font-semibold"
                                            style={{ color: group.color ?? "#0d0d0d" }}
                                        >
                                            {group.businessName}
                                        </h4>
                                    </div>
                                </TableCell>
                                {Object.entries(sellersSummary).map(([color, value]: [string, number]) => (
                                    <TableCell className={`h-6 flex-1 flex items-center  max-h-6 min-h-6 relative p-0 py-1`}>
                                        <Badge style={{ color: color, borderColor: color }} variant={'outline'} className="text-[11px] flex items-center gap-1 py-0" key={color}>
                                            <User size={13} />
                                            {value}
                                        </Badge>
                                    </TableCell>
                                ))}
                            </tr>
                        </AccordionTrigger>
                        <AccordionContent className="pb-0" style={{ paddingBottom: 0 }}>
                            <div>
                                {group.group.length > 0 &&
                                    group.group
                                        .filter((subGroup) => _.get(subGroup, "active", true))
                                        .sort((a, b) => a.businessName.localeCompare(b.businessName))
                                        .map((subGroup) => (
                                            <CellGroupAndUser
                                                firstGroup={false}
                                                selectedSeller={selectedSeller}
                                                handleSelectedUser={handleSelectedUser}
                                                key={subGroup._id}
                                                group={subGroup}
                                            />
                                        ))}
                            </div>
                            <tbody className="flex w-full flex-col text-base text-neutral-950 mt-2 py-0">
                                {orderUsers &&
                                    orderUsers
                                        .filter((user) => _.get(user, "active", true))
                                        .map((user) => <ItemUserCell selectedSeller={selectedSeller} handleSelectedUser={handleSelectedUser} user={user} key={user._id} />)}
                            </tbody>
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    usersGroup: state.main.usersGroup,
});

const mapDispatchToProps = {
    setUsersGroup: setUsersGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusCard);