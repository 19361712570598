import fetch from 'isomorphic-fetch'
import {getCookie} from './auth'
var API = process.env.API

export const getLast = () => {
    return fetch(`${API}/tracing`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const getCallMap = () => {
    return fetch(`${API}/tracing/callmap`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const getCallList = (from, to) => {
    return fetch(`${API}/tracing/last-calls?from=${from}&to=${to}`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const getCallListUsers = (from, to) => {
    return fetch(`${API}/tracing/last-calls-users?from=${from}&to=${to}`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const getStat = (name, userId, days = 14, groupId = undefined) => {
    return fetch(`${API}/stats/${name}?days=${days}&utcTime=${new Date().getTimezoneOffset()}&userId=${userId}${groupId ? `&groupId=${groupId}` : ''}`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const getPerformance = () => {
    return fetch(`${API}/goal/performance`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}