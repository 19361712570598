import { AttachMoney, Celebration, CheckCircleOutline, EmojiEvents, Group, RecordVoiceOver, SpatialTracking } from "@mui/icons-material"
import { Box, Chip, Divider, Grid, LinearProgress, Paper, Typography } from "@mui/material"
import _ from "lodash"
import { useQuery } from "react-query"
import { isAuth } from "../../actions/auth"
import { getDailyStats } from "../../actions/comercial/metas"
import Loading from "../core/Loading"
import Module from "../core/Module"


interface TrophyInterface {
    user: any,
}

interface ItemTrophy {
    icon: any,
    title: string,
    header: string,
    chip?: any
}


const ItemTrophy = ({icon, title, header, chip}: ItemTrophy) => {
    return <Grid container item xs={12} md={4}>
                <Grid item >
                    <Box sx={{background: "#cceeff", p: 2, borderRadius: 2}}>
                        {icon}
                    </Box>
                </Grid>
                <Grid item ml={1.5} xs pr={2}>
                    <Grid container justifyContent={'space-between'} alignItems="baseline">
                        <Grid item><Typography fontWeight={600} fontSize={18}>{title}</Typography></Grid>
                        {chip ? chip : <></>}
                    </Grid>

                    <Grid container alignItems="baseline" spacing={0}>
                        {/* <Grid item><Typography fontWeight={600} fontSize={18}>Racha</Typography></Grid> */}
                        <Grid item><Typography fontWeight={500} fontSize={15}>{header}</Typography></Grid>
                        
                    </Grid>
                    
                    {/* <LinearProgress value={100} variant="determinate" color="success" /> */}
                </Grid>

            </Grid>
}

const Trophy = ({user}:TrophyInterface) => {
    let userToUse = user
    if(!user){
        userToUse = _.get(isAuth(), '_id', '') as string
    }

    let statsQuery = useQuery(['user-stats-query',userToUse], () => getDailyStats(userToUse))

    // if(statsQuery.error) return <Module>
    //     {JSON.stringify(statsQuery.error.response.data)}
    // </Module>
    if(statsQuery.isFetching || statsQuery.error ||  statsQuery.data.data.error) return <Module sx={{pb:0}}>
        <Loading />
    </Module>
    
    let data = _.get(statsQuery, 'data.data' ,{})

    return <Paper
    sx={{
        boxShadow: "0 0 20px rgb(8 21 66 / 5%)",
        borderRadius: "10px !important",
        mt: 2,
        pb: 1.75,
        px: 2
    }}
    >
        <Grid container spacing={2} direction={{md: 'row'}} pb={0}>
                <ItemTrophy 
                icon={<SpatialTracking sx={{color:"#0080FF", fontSize: 32}} />}
                title={`${data.tracings}`}
                header={"Seguimientos de hoy"}
                // chip={<Chip label="Hoy" size="small"/>}
                />
                {!data.usesGoals ? 
                <ItemTrophy 
                icon={<Group sx={{color:"#0080FF", fontSize: 32}} />}
                title={`${data.contacts}`}
                header={"Contactos de hoy"}
                />
                : data.prizeWon != 0 || data.prizeWonBefore == 0 ? 
                <ItemTrophy 
                icon={<EmojiEvents sx={{color:"#0080FF", fontSize: 32}} />}
                title={`$${data.prizeWon}`}
                header={"Ganado este mes"}
                />
                :
                <ItemTrophy 
                icon={<EmojiEvents sx={{color:"#0080FF", fontSize: 32}} />}
                title={`$${data.prizeWonBefore}`}
                header={"Ganado mes pasado"}
                />
                }
                <ItemTrophy 
                icon={<AttachMoney sx={{color:"#0080FF", fontSize: 32}} />}
                title={data.sales}
                header={"Ventas este mes"}
                />
            </Grid>
        </Paper>
}


export default Trophy