import React, { useState } from "react";
import { Theme, Tooltip, IconButton } from "@mui/material";
// import "react-quill/dist/quill.snow.css";
import { useRouter } from "next/router";
import moment from 'moment'
import { BasicUser } from '../../interfaces/Interface'
import { SxProps } from "@mui/system";
import { Add } from "@mui/icons-material";
import Link from '../core/NextLinkWraped'

moment.locale();

interface InstanceCardAdd {
    user_id: string;
    sx?: SxProps<Theme>;
}

function InstanceCardAdd({ user_id }: InstanceCardAdd) {
    return (
        <Link href={`/evaluate/${user_id}`}>
            <Tooltip title="Crear una nueva instancia" placement="left">
                <IconButton aria-label="settings">
                    <Add/>
                </IconButton>
            </Tooltip>
        </Link>
    )
}

export default InstanceCardAdd;