import { AlertCircle, BarChart4, Info, Loader2 } from 'lucide-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DialogDescription, DialogTitle } from '../ui/dialog'
import dynamic from 'next/dynamic';
import moment from 'moment';
import { ArrowLeft } from 'lucide-react';
import { getGoalsFromSeller } from '@/actions/comercial/metas';
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

const chartsOptiopns = [{
    title: 'Contactos',
    keyGoal: 'contacts'
}, {
    title: 'Seguimientos',
    keyGoal: 'tracing'
}, {
    title: 'Ventas',
    keyGoal: 'sale'
}]

type CumulativeFunctionType = {
    achievedGoals: number[]
}

const generateDays = ({ dateStart, dateEnd }) => {
    const contentData: string[] = []
    for (let currentDate = moment(dateStart).startOf('day'); currentDate.diff(dateEnd, 'day') <= 0; currentDate.add(1, 'days')) {
        if (+currentDate.format('D') === 1) contentData.push(currentDate.format('D/M'))
        else contentData.push(currentDate.format('D'))
    }
    return contentData
}

export function calculateGoalPerDay({ days, goal, startDay }) {
    if (goal === 0) return new Array(days).fill(0);
    const contentExpectedPerday: number[] = [];
    let accumulatedGoalsDays = 0;
    let realDays = 0
    let day = moment(startDay).startOf('day');

    for (let i = 0; i < days; i++) {
        if (day.weekday() !== 6 && day.weekday() !== 0) {
            realDays += 1;
        }
        day.add(1, 'days');

    }

    day = moment(startDay).startOf('day');

    for (let i = 0; i < days; i++) {
        if (day.weekday() !== 6 && day.weekday() !== 0) {
            accumulatedGoalsDays += 1;
        }
        day.add(1, 'days');

        contentExpectedPerday.push(Math.round((goal / realDays) * accumulatedGoalsDays));
    }

    return contentExpectedPerday;
}

const getGoalAcumulative = ({ achievedGoals = [] }: CumulativeFunctionType) => {
    const contentSum: number[] = []

    achievedGoals.reduce((lastValue: number, currentValue: number) => {
        const sumNumbers = lastValue + currentValue
        contentSum.push(sumNumbers)
        return sumNumbers
    }, 0)
    return contentSum
}

export const generateMetricDefault = (startDate, endDate) => {
    const defaultValues = {}
    for (let dateToAdd = moment(startDate).startOf('day'); dateToAdd.diff(moment(endDate).startOf('day'), 'day') <= 0; dateToAdd.add(1, 'days')) {
        defaultValues[dateToAdd.format('YYYY/MM/DD')] = 0
    }
    return defaultValues
}

export default function GraphicsArea({ selectedSeller, handleDeelectSeller }) {
    const assignedGoal = useMemo(() => selectedSeller?.goal ?? [], [selectedSeller])
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [sellerArchivedGoal, setSellerArchivedGoal] = useState({
        sale: [],
        contacts: [],
        tracing: []
    })

    useEffect(() => {
        if (selectedSeller && selectedSeller.goal && selectedSeller.goal._id) {
            setLoading(true)
            const { _id, goal } = selectedSeller
            const generateDefaultMetricGoal = generateMetricDefault(goal.start, new Date()) // ????
            const fieldsNameGoal = chartsOptiopns.map(name => name.keyGoal)
            const newObjectMetrics: any = {}
            fieldsNameGoal.forEach(name => newObjectMetrics[name] = { ...generateDefaultMetricGoal })


            getGoalsFromSeller({ userId: _id, idGoal: goal._id ?? '', startGoal: goal.start, endGoal: goal.end })
                .then(async resFetch => {
                    let data = await resFetch.json()
                    Object.entries(data).map(([key, value]: [string, { string: number }]) => {
                        Object.entries(value).forEach(([keyDate, dateValue]) => {
                            newObjectMetrics[key][keyDate] = dateValue
                        })
                    })

                    Object.entries(newObjectMetrics)
                        .forEach(([key, value]: [string, { string: number }]) => newObjectMetrics[key] = Object.values(value))
                    setSellerArchivedGoal(newObjectMetrics)
                })
                .catch(() => setError('Ocurrió un error al obtener las métricas del vendedor. Por favor, inténtalo de nuevo.'))
                .finally(() => setLoading(false))
        }
    }, [selectedSeller])

    const charts: Array<any> = useMemo(() => {
        if (!selectedSeller || assignedGoal.length === 0) return []

        return chartsOptiopns.map((chart) => {
            const achievedGoals = getGoalAcumulative({ achievedGoals: (sellerArchivedGoal[chart.keyGoal] ?? []) })
            const startDayAndFinishOfTheGoal = generateDays({ dateEnd: assignedGoal?.end, dateStart: assignedGoal?.start })
            const expectedGoalsPerDay = calculateGoalPerDay({ startDay: assignedGoal?.start, days: startDayAndFinishOfTheGoal.length, goal: assignedGoal?.goal[0]?.[chart.keyGoal] })

            return {
                option: {
                    chart: {
                        width: "100%",
                        type: 'line',
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: 'smooth',
                        dashArray: [0, 3],
                        lineWidth: 0.1,
                    },
                    title: {
                        text: '',
                        align: 'left',
                        size: 12,
                        style: {
                            fontSize: '15px',
                            fontWeight: 500,
                        },
                    },
                    xaxis: {
                        categories: startDayAndFinishOfTheGoal,
                    },
                    legend: {
                        position: 'top',
                    },
                    colors: ['#0180FF', '#d6d6d6']
                },
                series: [{
                    name: chart.title,
                    data: achievedGoals,
                    labels: {
                        show: false,
                    },
                }, {
                    name: 'Esperado',
                    data: expectedGoalsPerDay,
                    labels: {
                        show: false,
                    },
                }]
            }
        })
    }, [assignedGoal, selectedSeller, sellerArchivedGoal])

    return <div className={`h-full lg:w-[290px] lg:bg-[#f9f9f9] bg-white py-4 ${selectedSeller ? 'flex absolute w-full h-full' : 'hidden'} lg:flex  flex-col gap-2 bg-red  lg:relative overflow-y-auto`}>
        <div className='flex pl-0 lg:pl-5 items-center  relative gap-2'>
            <button className='w-[30px] left-4 lg:hidden block h-[30px] absolute rounded-full shrink-0' onClick={handleDeelectSeller}>
                <ArrowLeft size={22} />
            </button>
            <div className='lg:text-start  flex-grow text-center'>
                <DialogTitle className='mb-1'>Resumen del vendedor</DialogTitle>
                <DialogDescription className='text-[14px]'>
                    {selectedSeller?.name} {selectedSeller?.lastname}
                </DialogDescription>
            </div>
        </div>
        <div className="relative flex-grow items-stretch  flex flex-col">
            {error ? <div className='px-5'>
                <Alert variant="destructive">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>
                        {error}
                    </AlertDescription>
                </Alert>
            </div> : selectedSeller
                ? isLoading
                    ? <div className='w-full flex-grow flex justify-center items-center'>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin text-primary " />
                    </div>
                    : charts.length > 0 ? <div className='flex-grow lg:pr-6 pr-4 flex flex-col justify-between'>
                        {charts.map((chart, inx) => (
                            <>
                                <Chart key={`chart${inx}`} options={chart.option} series={chart.series} type="line"></Chart>
                            </>
                        ))}
                    </div> : <div className='flex justify-center flex-grow w-full items-center flex-col gap-2'>
                        <span className="text-primary">
                            <BarChart4 size={80} />
                        </span>
                        <h5 className="text-[15px] text-neutral-700">Sin meta establecida</h5>
                    </div>
                : <div className="flex justify-center flex-grow w-full items-center flex-col gap-2">
                    <span className="text-primary">
                        <BarChart4 size={80} />
                    </span>
                    <h5 className="text-[15px] text-neutral-700">Seleccióna un vendedor</h5>
                </div>}
        </div>
    </div>
}

