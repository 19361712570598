import React, { useState } from "react";
import { Avatar, Box, Card, CardActionArea, CardHeader, Grid, Theme, Icon, Typography, Chip, Badge } from "@mui/material";
// import "react-quill/dist/quill.snow.css";
import { useRouter } from "next/router";
import moment from 'moment'
import { BasicUser, InterfaceTypeComplete, evaluationRating, evaluationKeys } from '../../interfaces/Interface'
import { SxProps } from "@mui/system";
import { Chat, PriorityHigh } from "@mui/icons-material";
import Link from '../core/NextLinkWraped'
import ChipAvatar from '../core/ChipAvatar'
import { styled } from '@mui/material/styles';
import { isAuth } from "../../actions/auth";
import InstanceListModule from "./InstanceListModule";
import EvaluationChip from "./EvaluationChip";

moment.locale();

interface InstanceCard {
    instance: any;
    sx: SxProps<Theme>;
    showVendedor?: boolean;
}

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

function InstanceCard({ sx, instance, showVendedor }: InstanceCard) {
    var router = useRouter()

    let { title, description, createdAt, type, createdBy, _id, user, seenByUser } = instance
    return (
        <Link href={`/evaluation/${_id}`}>
            <Card sx={sx}>
                <CardActionArea>
                    <Box sx={{ gridTemplateColumns: '44px auto', display: 'grid' }} >
                        <Box sx={{ p: '7px 5px 5px 7px' }}>
                            {(instance.user._id == isAuth()._id && !seenByUser) || (instance.requiresResponse && (!instance.comments || instance.comments.filter((com) => com.createdBy == instance.user._id).length == 0)) ? (
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    variant="dot"
                                >
                                    <Avatar sx={{ width: 32, height: 32, fontSize: 18, bgcolor: InterfaceTypeComplete[type].color, color: `#fff !important` }}>
                                        {InterfaceTypeComplete[type].icon.type.render()}
                                    </Avatar>
                                </StyledBadge>
                            ) : (
                                <Avatar sx={{ width: 32, height: 32, fontSize: 18, bgcolor: InterfaceTypeComplete[type].color, color: `#fff !important` }}>
                                    {InterfaceTypeComplete[type].icon.type.render()}
                                </Avatar>
                            )}
                        </Box>
                        <Box sx={{ p: '7px', pl: '2px' }}>
                            <Grid container>
                                <Grid item>
                                    <Typography sx={{ p: 0, m: 0, lineHeight: '16px', fontSize: '14px', fontWeight: '600' }} >{title}</Typography>
                                </Grid>
                            </Grid>
                            {showVendedor &&
                                <Grid item sx={{ mt: '3px' }}>
                                    <ChipAvatar userChip={user} size="small" seen={seenByUser} commented={instance.comments && instance.comments.filter((com) => com.createdBy == instance.user._id).length > 0} />
                                </Grid>
                            }
                            {type == 'evaluation' ? (
                                <Grid container sx={{ fontSize: '0.9em', py: '4px' }}>
                                    {
                                        [...instance.evaluations].map((eva) => (
                                            <EvaluationChip keyCode={eva.key} {...eva} />
                                        ))
                                    }
                                </Grid>
                            ) : (
                                <Grid container sx={{ fontSize: '0.9em', py: '4px' }}>
                                    {description}
                                </Grid>
                            )}
                            <Grid item>
                                {instance.user._id == isAuth()._id && instance.requiresResponse && (!instance.comments || instance.comments.filter((com) => com.createdBy == instance.user._id).length == 0) && <Chip icon={<PriorityHigh />} label={"Respuesta obligatoria"} color={'error'} variant={"filled"} size="small" sx={{mb: 0.5}}/>}
                            </Grid>
                            <Grid item>
                                <Typography sx={{ pt: 0, m: 0, pl: '4px', lineHeight: '16px', fontSize: '12px', color: '#aaa' }}>{moment(createdAt).calendar()} por <b>{createdBy.name} {createdBy.lastname}</b> <Chip icon={<Chat sx={{ width: '12px', color: '#aaa !important' }} />} sx={{ border: '0px', color: '#aaa !important', fontSize: '12px' }} label={!instance.comments ? 0 : instance.comments.length} size="small" variant="outlined" /></Typography>
                            </Grid>
                        </Box>
                    </Box>
                </CardActionArea>
            </Card>
        </Link>
    );
}

export default InstanceCard;