


const getColorToCodeMui = (color:string) => {

    switch(color){

        case "default":
            return "#BDBDBD"
        case "error":
            return "#D32F2F"
        case "success":
            return "#2F7C31"
        case "primary":
            return "#0088D1"
        case "secondary":
            return "#9C27B0"
        case "info":
            return "#0088D1"
        case "warning":
            return "#ED6B04"
        default:
            return "#BDBDBD"
    }


}

export default getColorToCodeMui