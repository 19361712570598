import { AttachMoney, Call, DateRange, Description, Info, InfoOutlined, Login, MeetingRoom, People, Person, QuestionMark, Schedule, SentimentDissatisfied, SentimentSatisfied, SentimentSatisfiedAlt, SentimentVeryDissatisfied, SentimentVerySatisfied, Smartphone } from "@mui/icons-material"
import { Avatar, ButtonBase, CardHeader, Grid, Typography, Card, Chip, LinearProgress, Box, SxProps } from "@mui/material"
import { useMemo } from "react"
import getColorToCodeMui from "../../../helpers/getColorToCodeMUI"
import { AlertMap, getColorByLevel } from "./AlertMap"
import { IconAction } from "./IconAction"


interface IconLevelInterface {
    level: number,
    sx?:SxProps
}

export const IconLevel = ({level, sx}:IconLevelInterface) => {
    let sxIcon = {fontSize: 18,color: `${getColorToCodeMui(getColorByLevel(level))} !important`}
    switch(level){
        case 0:
            return <SentimentVeryDissatisfied sx={{...sxIcon, ...sx}}/>
        case 1:
            return <SentimentDissatisfied sx={{...sxIcon, ...sx}}/>
        case 2:
            return <SentimentSatisfied sx={{...sxIcon, ...sx}}/>
        case 3:
            return <SentimentSatisfiedAlt sx={{...sxIcon, ...sx}}/>
        case 4:
            return <SentimentVerySatisfied sx={{...sxIcon, ...sx}}/>
        
        default:
            return <Person sx={{...sxIcon, ...sx}}/>
    
    }
}



const getAlertSubTitle = (numbers) => {

    return <>
        {numbers.map((value: number, index) => {
            let color = getColorByLevel(index)
            return <Chip 
                    label={value} 
                    icon={<IconLevel level={index}/>} 
                    sx={{ background: 'rgba(0,0,0,0)', color: getColorToCodeMui(color), fontWeight: 600, p: 0} } 
                    size={"small"}
                    />
        })}
    </>
}


const AlertCardGroup = ({alert, onPress}) => {
    let alerta = useMemo(() => AlertMap(alert.alert, 0), [alert])


    return (
        <Grid item>
            <Card variant="outlined" sx={{
                p:0, 
                border: "1px solid #fefefe", 
                background: "#fefefe",
                boxShadow: "0 0 10px rgb(8 21 66 / 4%)",
                borderRadius: "5px !important",
                }}
                >
                <ButtonBase
                    sx={{display: 'block', textAlign: 'initial', width: '100%'}}
                    onClick={() => onPress(alert.alert)}
                >
                    <CardHeader
                        sx={{px:2, py:1.25}}
                        title={<Typography fontSize={"14px"} fontWeight={600}>{alerta.indicatorTitle}</Typography>}
                        subheader={getAlertSubTitle(alert.levels)}
                        avatar={
                        <Avatar sx={{ width: '26px', height: '26px', background: getColorToCodeMui(getColorByLevel(alert.value))}}>
                            <IconAction name={alerta.icon} />
                        </Avatar>}
                        // action={<ArrowForward  sx={{fontSize: 20}}/>}
                    />
                </ButtonBase>
            </Card>
    </Grid>

    )
}

export default AlertCardGroup