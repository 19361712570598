import { ArrowForward, ArrowForwardIos, ArrowRight, ArrowRightAlt, AttachMoney, Call, Celebration, CheckCircleOutline, EmojiEvents, Expand, ExpandLess, ExpandMore, Group, Phone, PointOfSale, RecordVoiceOver, Scale, SpatialTracking } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, ButtonBase, Card, CardHeader, Chip, Collapse, Divider, Grid, IconButton, LinearProgress, Paper, Tooltip, Typography } from "@mui/material"
import _ from "lodash"
import { useMemo, useState } from "react"
import { useQuery } from "react-query"
import { isAuth } from "../../../actions/auth"
import { getDailyAlerts, getDailyAlertsGroup, getDailyStats } from "../../../actions/comercial/metas"
import { list, listAll, listUser } from "../../../actions/instance"
import getColorToCodeMui from "../../../helpers/getColorToCodeMUI"
import Loading from "../../core/Loading"
import Module from "../../core/Module"
import InstanceCard from "../../evaluate/InstanceCard"
import AlertAllModal from "./AlertAllModal"
import AlertCard from "./AlertCard"
import AlertCardGroup from "./AlertCardGroup"
import { AlertMap } from "./AlertMap"
import AlertShowUsersModal from "./AlertShowUsersModal"


interface AlertReportInterface {
    group: string,
    clickedAvisos?: () => void
}


const AlertGroupReport = ({group, clickedAvisos}:AlertReportInterface) => {
    const [show, setShow] = useState<false | string>(false)
    const [showAll, setShowAll] = useState(false)
    
    let statsQuery = useQuery(['group-alerts-query',group], () => getDailyAlertsGroup(group))
    
    let data = _.get(statsQuery, 'data.data' ,{})
    
    const relevantAlertList:any[] = useMemo(() => {
        let local_alert = _.get(statsQuery, 'data.data.alerts', [])

        return local_alert.map((a) => {
            let points = 0
            let persons = 0

            a[1].map((levelValue, levelIndex) => {
                points+= levelValue * levelIndex
                persons += levelValue
            })
            

            return {
                alert: a[0],
                levels: a[1],
                value: Math.floor(points/persons),
                points,
                persons
            }
        }).sort((a,b) => a.value - b.value == 0 ? a.points - b.points : a.value - b.value)

    },[statsQuery.data])

    const userAlerts = useMemo(() => {
        return _.get(statsQuery, 'data.data.userAlerts', [])
    }, [statsQuery.data])


    if(statsQuery.isFetching || statsQuery.error ||  statsQuery.data.data.error) return <Module sx={{pb:0, height: '100%'}}>
        <Loading />
    </Module>

    return <Module 
            titleElement={<span onClick={clickedAvisos}>Alertas</span>}
            sx={{ height: "100%" }}
            sxCardContent={{p:0}}
            >
                <AlertShowUsersModal open={show} handleClose={() => setShow(false)} userAlerts={userAlerts}/>
                <Grid item container direction={{md: 'column'}} pb={0} spacing={0} rowSpacing={0.5} xs>
                        {relevantAlertList.slice(0, 5).map((alert) => <AlertCardGroup alert={alert} onPress={value => setShow(value)}/>)}
                        <Collapse in={showAll}>
                            {relevantAlertList.slice(5,relevantAlertList.length).map((alert) => <AlertCardGroup alert={alert} onPress={value => setShow(value)}/>)}
                        </Collapse>

                        <Button onClick={() => setShowAll(!showAll)}>{showAll? 'Ver menos' : (relevantAlertList.length - 5 <= 0 ? 'Ver todos' : `Ver ${relevantAlertList.length - 5} más`)} </Button>
                </Grid>
            </Module>
}


export default AlertGroupReport