import { Template } from "@/models/templates/types";
import { AvTimer, Campaign, Celebration, Dangerous, TextSnippet, SentimentVeryDissatisfied, SentimentDissatisfied, SentimentSatisfied, SentimentSatisfiedAlt, SentimentVerySatisfied, DirectionsWalk } from "@mui/icons-material";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ReactNode } from "react";

export interface Instance {
  title: string;
  body: string;
  requiresResponse: boolean;
  contacts: string[];
  user: string;
  type: 'evaluation' | 'warning' | 'late' | 'miss' | 'incentive' | 'visit';
}

export interface InstanceWithUser extends Omit<Instance, 'user'> {
  user: InstanceUser;
  seenByUser?: boolean;
  createdBy: InstanceUser;
  createdAt?: string;
  updatedAt?: string;
  _id: string;
  comments: Array<any>;
}

export interface InstanceUser extends BasicUser {
  email?: string;
  phone?: string;
}


export interface BasicUser {
  name?: string;
  lastname?: string;
  _id?: string;
}

export interface InstanceError {
  title: boolean | string;
  body: boolean | string;
  requiresResponse: boolean | string;
  contacts: boolean | string;
  user?: boolean | string;
  type?: boolean | string;
}


export enum InterfaceType {
  evaluation = 'evaluacion',
  warning = 'advertencia',
  late = 'llegada tarde',
  miss = 'falta',
  incentive = 'estimulo',
  visit = 'visita'
}


export enum evaluationKey {
  contact = 'contactos',
  tracing = 'seguimientos',
  sale = 'ventas'
}

interface InterfaceTypeCompleteElement {
  name: string;
  //   icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
  //     muiName: string;
  // };
  icon: any;
  color: string;
}


export interface evaluationItem {
  key: 'sale' | 'tracing' | 'contact';
  title: string;
  rating: number;
  body: string;
}

export enum evaluationKeys {
  sale = 'Ventas',
  tracing = 'Seguimientos',
  contact = 'Contactos'
}

export interface Evaluation {
  title: string;
  user: string;
  type: "evaluation";
  evaluations: evaluationItem[];
  requiresResponse: boolean;
  requiresSelfEvaluation: boolean;
}

export interface EvaluationWithUser extends Omit<Evaluation, 'user'> {
  user: InstanceUser;
  seenByUser?: boolean;
  body?: string;
  createdBy: InstanceUser;
  createdAt?: string;
  updatedAt?: string;
  _id: string;
  comments: Array<any>;
}

export interface evaluationItemError {
  key: string;
  rating: boolean | string;
  body: boolean | string;
}

export interface EvaluationError {
  title: boolean | string;
  user?: boolean | string;
  evaluations: evaluationItemError[];
}


export const InterfaceTypeComplete = {
  evaluation: { name: 'evaluacion', icon: TextSnippet, color: "#9E9E9E" } as InterfaceTypeCompleteElement,
  warning: { name: 'advertencia', icon: Campaign, color: "#F44336" } as InterfaceTypeCompleteElement,
  late: { name: 'llegada tarde', icon: AvTimer, color: "#FFC107" } as InterfaceTypeCompleteElement,
  miss: { name: 'falta', icon: Dangerous, color: "#FF5722" } as InterfaceTypeCompleteElement,
  incentive: { name: 'estimulo', icon: Celebration, color: "#4CAF50" } as InterfaceTypeCompleteElement,
  visit: { name: 'visita', icon: DirectionsWalk, color: "#0080ff" } as InterfaceTypeCompleteElement,
}


export const evaluationRating = {
  0: {
    icon: SentimentSatisfied,
    label: 'sin calificar',
    color: 'default'
  },
  1: {
    icon: SentimentVeryDissatisfied,
    label: "deben mejorar",
    color: 'error'
  },
  2: {
    icon: SentimentDissatisfied,
    label: "pueden mejorar",
    color: 'warning'
  },
  3: {
    icon: SentimentSatisfied,
    label: "son correctos",
    color: 'info'
  },
  4: {
    icon: SentimentSatisfiedAlt,
    label: "son buenos",
    color: 'success'
  },
  5: {
    icon: SentimentVerySatisfied,
    label: "excelentes",
    color: 'secondary'
  },
};

export type CountErrors = {
  readonly errors: number
  readonly create: number
  readonly documentToUpdate: number
}

export interface IFetchFile extends CountErrors {
  readonly documents: Array<any>

}
export interface TemplatePersonalize {
  readonly field: string
  readonly label: string
}

