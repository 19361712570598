import { Call } from "@mui/icons-material"
import { Avatar, ButtonBase, CardHeader, Grid, Typography, Card } from "@mui/material"
import { useMemo } from "react"
import getColorToCodeMui from "../../../helpers/getColorToCodeMUI"
import { AlertMap } from "./AlertMap"
import { IconAction } from "./IconAction"



const AlertCard = ({alert}) => {
    let alerta = useMemo(() => AlertMap(alert.alert, alert.level), [alert])


    return (
        <Grid item>
            <Card variant="outlined" sx={{
                p:0, 
                border: "1px solid #fefefe", 
                background: "#fefefe",
                boxShadow: "0 0 10px rgb(8 21 66 / 4%)",
                borderRadius: "5px !important",
                }}
                >
                <ButtonBase
                    sx={{display: 'block', textAlign: 'initial', width: '100%'}}
                    onClick={() => console.log('clickeable')}
                >
                    <CardHeader
                        sx={{px:2, py:1.25}}
                        title={<Typography fontSize={"14px"} fontWeight={600}>{alerta.title}</Typography>}
                        subheader={alerta.description}
                        avatar={
                        <Avatar sx={{ width: '26px', height: '26px', background: getColorToCodeMui(alerta.color)}}>
                            <IconAction name={alerta.icon} />
                        </Avatar>}
                        // action={<ArrowForward  sx={{fontSize: 20}}/>}
                    />
                </ButtonBase>
            </Card>
    </Grid>

    )
}

export default AlertCard