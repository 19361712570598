import React, { useState, useEffect, ReactElement } from "react";
import dynamic from "next/dynamic";
import defaultOptions from "./spark_default_options";
import {
    Box,
    Chip,
    FormControl,
    Grid,
    IconButton,
    InputBase,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    Button,
    Tooltip,
    Typography,
} from "@mui/material";
import { getStat } from "../../actions/tracing";
import {
    ArrowDropDown,
    ArrowDropUp,
    Close,
    DateRange,
    Remove,
    Today,
    FilterAlt,
} from "@mui/icons-material";
import { DataGrid, esES, GridColumns } from "@mui/x-data-grid";
import moment from "moment";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import FilterMultiSelect from "../reports/FilterMultiSelect";

moment().locale("es");

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

interface GraphItem {
    icon: React.ReactElement<any | string | React.JSXElementConstructor<any>>;
    source: string;
    dateField?: string;
    action?: "count" | "sum";
    divide?: number;
    field?: string;
    title: string;
    userId?: string;
    groupId?: string;
    columns: GridColumns;
    format?: string;
    filters?: string[];
}

const GraphItem = ({
    groupId,
    format,
    source,
    title,
    icon,
    dateField = "createdAt",
    action = "count",
    field = "_id",
    divide = 1,
    userId,
    columns,
    filters,
}: GraphItem) => {
    const [values, setValues] = useState({
        today: 0,
        week: 0,
        prev_week: 0,
        yesterday: 0,
    });

    const [open, setOpen] = useState<boolean>(false);
    const [list, setList] = useState([]);
    const [series, setSeries] = useState([]);

    const usarAyer = useSelector((state: any) => state.main.usarAyer);

    useEffect(() => {
        getStat(source, userId, usarAyer ? 14 : 13, groupId).then(
            (response) => {
                if (!response || response.error) return console.log(response);
                setList(response);
                let today = 0,
                    week = 0,
                    prev_week = 0,
                    yesterday = 0;
                let week_series = [0, 0, 0, 0, 0, 0, 0];
                let last_week_series = [0, 0, 0, 0, 0, 0, 0];
                let tomorow_date = new Date();
                tomorow_date.setHours(0, 0, 0, 0);
                let today_date = new Date();
                if (usarAyer) {
                    today_date = new Date(tomorow_date);
                    today_date.setDate(today_date.getDate() - 1);
                } else {
                    today_date = new Date(tomorow_date);
                    tomorow_date.setDate(tomorow_date.getDate() + 3);
                }
                let yesterday_day = new Date(today_date);
                yesterday_day.setDate(today_date.getDate() - 1);
                let week_day = new Date(today_date);
                week_day.setDate(today_date.getDate() - 6);
                let endMonth = new Date(
                    week_day.getFullYear(),
                    week_day.getMonth() + 1,
                    0
                );
                let last_week_day = new Date(today_date);
                last_week_day.setDate(today_date.getDate() - 13);

                response.map((item) => {
                    let sum_value = 1;
                    let fecha;
                    if (format) {
                        fecha = moment(item[dateField], format).toDate();
                    } else {
                        fecha = moment(item[dateField]).toDate();
                    }
                    if (action == "sum") {
                        sum_value = parseInt(item[field]);
                        if (isNaN(sum_value)) sum_value = 0;
                    }

                    if (fecha < tomorow_date && fecha >= today_date) {
                        today += sum_value;
                    }
                    if (fecha < today_date && fecha >= yesterday_day) {
                        yesterday += sum_value;
                    }
                    if (fecha < tomorow_date && fecha >= week_day) {
                        week += sum_value;
                        if (fecha.getDate() - week_day.getDate() < 0) {
                            week_series[
                                endMonth.getDate() -
                                    week_day.getDate() +
                                    fecha.getDate() -
                                    1
                            ] += sum_value;
                        } else {
                            week_series[fecha.getDate() - week_day.getDate()] +=
                                sum_value;
                        }
                    } else {
                        if (
                            fecha < week_day &&
                            fecha.getTime() >= last_week_day.getTime()
                        ) {
                            prev_week += sum_value;

                            if (fecha.getDate() - last_week_day.getDate() < 0) {
                                last_week_series[
                                    endMonth.getDate() -
                                        last_week_day.getDate() +
                                        fecha.getDate() -
                                        1
                                ] += sum_value;
                            } else {
                                last_week_series[
                                    fecha.getDate() - last_week_day.getDate()
                                ] += sum_value;
                            }
                        } else {
                            console.log(item);
                        }
                    }
                });
                setValues({
                    today: Math.floor(today / divide),
                    week: Math.floor(week / divide),
                    prev_week: Math.floor(prev_week / divide),
                    yesterday: Math.floor(yesterday / divide),
                });
                setSeries([
                    {
                        name: title,
                        data: week_series.map((day) =>
                            Math.floor(day / divide)
                        ),
                    },
                ]);
            }
        );
    }, [
        source,
        groupId,
        title,
        dateField,
        action,
        field,
        divide,
        userId,
        usarAyer,
    ]);

    let options = defaultOptions;

    interface ChipValue {
        title: string;
        value: number;
        compareValue: number;
        valueDateTitle: string;
        compareValueDateTitle: string;
    }

    let ChipValue = ({
        title,
        value,
        compareValue,
        valueDateTitle = "ayer",
        compareValueDateTitle = "hoy",
    }: ChipValue) => {
        let porcentaje = 0;
        if (compareValue != 0) {
            porcentaje = Math.floor(
                ((value - compareValue) / compareValue) * 100
            );
        } else {
            porcentaje = Math.floor(((value - compareValue) / 1) * 100);
        }
        let color = "default";
        let arrow = "normal";

        if (porcentaje < -10) {
            color = "#dc3545";
            arrow = "down";
        } else if (porcentaje < -2) {
            color = "#ffc107";
            arrow = "down";
        } else if (porcentaje < 2) {
            color = "default";
            arrow = "normal";
        } else if (porcentaje < 10) {
            color = "#28a745";
            arrow = "up";
        } else if (porcentaje >= 10) {
            color = "#0080ff";
            arrow = "up";
        }

        return (
            <Tooltip
                title={`${
                    porcentaje >= 0 ? "Aumento del " : "Disminución del "
                }${Math.abs(
                    porcentaje
                )}% de ${title} entre ${valueDateTitle} y ${compareValueDateTitle} |\n ${title} de ${valueDateTitle}: ${value} |\n ${title} de ${compareValueDateTitle}: ${compareValue}`}
            >
                <Grid container alignItems={"center"} mx={1}>
                    <Grid item>
                        {arrow == "up" ? (
                            <ArrowDropUp sx={{ fontSize: 18, color: color }} />
                        ) : arrow == "normal" ? (
                            <Remove sx={{ fontSize: 12, color: color }} />
                        ) : (
                            <ArrowDropDown
                                sx={{ fontSize: 18, color: color }}
                            />
                        )}
                    </Grid>
                    <Grid item sx={{ fontSize: 12, color: color }}>
                        {Math.abs(porcentaje)}%
                    </Grid>
                </Grid>
            </Tooltip>
        );
    };

    let handleClose = () => {
        setOpen(false);
    };

    const ModalList = () => {
        const [searched, setSearched] = useState<string>("");
        const [listTable, setListTable] = useState(list);
        const [vendedorFilter, setVendedorFilter] = useState(["all"]);
        const [originFilter, setOiriginFilter] = useState(["all"]);
        const [dateFilter, setDateFilter] = useState([
            usarAyer ? "ayer" : "hoy",
        ]);
        const [statusFilter, setStatusFilter] = useState(["all"]);
        const [fuentesFilter, setFuenteFilter] = useState(["all"]);
        const [agentFilter, setAgentFilter] = useState(["all"]);
        const [viasFilter, setViasFilter] = useState(["all"]);
        const [cierresFilter, setCierresFilter] = useState(["all"]);
        const [anteriorFilter, setAnteriorFilter] = useState(["all"]);
        const [showFilter, toggleFilter] = useState("none");
        const [origenes, setOrigenes] = useState([]);
        const [vendedores, setVendedores] = useState([]);
        const [estados, setEstados] = useState([]);
        const [fuentes, setFuentes] = useState([]);
        const [agentes, setAgentes] = useState([]);
        const [cierres, setCierres] = useState([]);
        const [anterior, setAnterior] = useState([]);
        const [vias, setVias] = useState([]);

        useEffect(() => {
            //CALCULAMOS LOS FILTROS DISPONIBLES

            let origenesMap = [];
            let vendedoresMap = [];
            let estadosMap = [];
            let fuentesMap = [];
            let agentesMap = [];
            let viasMap = [];
            let cierresMap = [];
            let anteriorMap = [];
            list.map((val) => {
                if (val.output) {
                    if (val.output.message && val.output.message.via) {
                        if (!viasMap.includes(val.output.message.via)) {
                            viasMap.push(val.output.message.via);
                        }
                    }
                }

                if (val.leads && val.leads[0].source && val.leads[0]) {
                    if (!origenesMap.includes(val.leads[0].source)) {
                        origenesMap.push(val.leads[0].source);
                    }
                }
                if (val.user && val.user.name && val.user.lastname) {
                    let nombre = `${val.user.name} ${val.user.lastname}`;
                    if (!vendedoresMap.includes(nombre)) {
                        vendedoresMap.push(nombre);
                    }
                }

                if (val.status) {
                    if (!estadosMap.includes(val.status)) {
                        estadosMap.push(val.status);
                    }
                }

                if (val.from) {
                    let from = val.from;
                    switch (from) {
                        case "archivedUntilSchedule":
                            from = "Recordatorio";
                            break;
                        case "waitingForReply":
                            from = "Esperando respuesta";
                    }
                    if (!anteriorMap.includes(from)) {
                        anteriorMap.push(from);
                    }
                    val.from = from;
                }

                if (val.to) {
                    let to = val.to;
                    switch (to) {
                        case "archivedUntilSchedule":
                            to = "Recordatorio";
                            break;
                        case "waitingForReply":
                            to = "Esperando respuesta";
                    }
                    if (!cierresMap.includes(to)) {
                        cierresMap.push(to);
                    }
                    val.to = to;
                }

                if (val.type) {
                    if (!fuentesMap.includes(val.type)) {
                        fuentesMap.push(val.type);
                    }
                }

                if (val.agent && val.agent.firstName && val.agent.lastName) {
                    let nombre = `${val.agent.firstName} ${val.agent.lastName}`;
                    val.vendedor = nombre;
                    if (!agentesMap.includes(nombre)) {
                        agentesMap.push(nombre);
                    }
                }

                if (val.agent_name) {
                    let nombre = `${val.agent_name} ${val.agent_lastname}`;
                    val.vendedor = nombre;
                    if (!agentesMap.includes(nombre)) {
                        agentesMap.push(nombre);
                    }
                }
            });
            setVias(viasMap);
            setFuentes(fuentesMap);
            setEstados(estadosMap);
            setVendedores(vendedoresMap);
            setOrigenes(origenesMap);
            setAgentes(agentesMap);
            setCierres(cierresMap);
            setAnterior(anteriorMap);
            //=======================================

            //Filtramos la lista segun los filtros seleccionados
            let aux = list.filter((val) => {
                if (searched) {
                    let keys_cumplen = Object.keys(val).filter((key) => {
                        if (!val[key] || typeof val[key] != "string") {
                            return false;
                        } else {
                            return val[key].includes(searched);
                        }
                    });

                    if (keys_cumplen.length == 0) {
                        return false;
                    }
                }

                if (originFilter[0] != "all" && originFilter.length != 0) {
                    if (val.leads && val.leads[0].source && val.leads[0]) {
                        if (!originFilter.includes(val.leads[0].source)) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }

                if (cierresFilter[0] != "all" && cierresFilter.length != 0) {
                    if (val.to) {
                        if (!cierresFilter.includes(val.to)) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }

                if (anteriorFilter[0] != "all" && anteriorFilter.length != 0) {
                    if (val.from) {
                        if (!anteriorFilter.includes(val.from)) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }

                if (vendedorFilter[0] != "all" && vendedorFilter.length != 0) {
                    if (val.user && val.user.name && val.user.lastname) {
                        let nombre = `${val.user.name} ${val.user.lastname}`;
                        if (!vendedorFilter.includes(nombre)) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }

                if (viasFilter[0] != "all" && viasFilter.length != 0) {
                    if (
                        val.output &&
                        val.output.message &&
                        val.output.message.via
                    ) {
                        if (!viasFilter.includes(val.output.message.via)) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }

                if (statusFilter[0] != "all" && statusFilter.length != 0) {
                    if (val.status) {
                        if (!statusFilter.includes(val.status)) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }

                if (fuentesFilter[0] != "all" && fuentesFilter.length != 0) {
                    if (val.type) {
                        if (!fuentesFilter.includes(val.type)) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }

                if (agentFilter[0] != "all" && agentFilter.length != 0) {
                    if (
                        val.agent &&
                        val.agent.firstName &&
                        val.agent.lastName
                    ) {
                        let nombre = `${val.agent.firstName} ${val.agent.lastName}`;
                        if (!agentFilter.includes(nombre)) {
                            return false;
                        }
                    } else if (val.vendedor) {
                        if (!agentFilter.includes(val.vendedor)) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }

                if (dateFilter[0] != "all" && dateFilter.length != 0) {
                    if (val.created) {
                        let fechaVal = moment(val.created).format("YYYY-MM-DD");
                        let fechaActual = moment().format("YYYY-MM-DD");

                        for (let fecha of dateFilter) {
                            if (fecha == "hoy") {
                                if (fechaVal != fechaActual) {
                                    return false;
                                }
                            }
                            if (fecha == "ayer") {
                                if (
                                    moment(fechaVal).date() !=
                                    moment(fechaActual).date() - 1
                                ) {
                                    return false;
                                }
                            }
                            if (fecha == "7dias") {
                                if (
                                    moment(val.created).isBefore(
                                        moment(
                                            new Date().getTime() - 84000000 * 8
                                        )
                                    )
                                ) {
                                    return false;
                                }
                            }
                            if (fecha == "14dias") {
                                if (
                                    moment(val.created).isBefore(
                                        moment(
                                            new Date().getTime() - 84000000 * 14
                                        )
                                    )
                                ) {
                                    return false;
                                }
                            }
                        }
                    }

                    if (dateFilter[0] != "all" && dateFilter.length != 0) {
                        if (val.createdAt) {
                            let fechaVal = moment(val.createdAt).format(
                                "YYYY-MM-DD"
                            );
                            let fechaActual = moment().format("YYYY-MM-DD");

                            for (let fecha of dateFilter) {
                                if (fecha == "hoy") {
                                    if (fechaVal != fechaActual) {
                                        return false;
                                    }
                                }
                                if (fecha == "ayer") {
                                    if (
                                        moment(fechaVal).date() !=
                                        moment(fechaActual).date() - 1
                                    ) {
                                        return false;
                                    }
                                }
                                if (fecha == "7dias") {
                                    if (
                                        moment(val.createdAt).isBefore(
                                            moment(
                                                new Date().getTime() -
                                                    84000000 * 8
                                            )
                                        )
                                    ) {
                                        return false;
                                    }
                                }
                                if (fecha == "14dias") {
                                    if (
                                        moment(val.createdAt).isBefore(
                                            moment(
                                                new Date().getTime() -
                                                    84000000 * 14
                                            )
                                        )
                                    ) {
                                        return false;
                                    }
                                }
                            }
                        }
                    }

                    if (val.timestamp) {
                        let fechaVal = moment(val.timestamp).format(
                            "YYYY-MM-DD"
                        );
                        let fechaActual = moment().format("YYYY-MM-DD");

                        for (let fecha of dateFilter) {
                            if (fecha == "hoy") {
                                if (fechaVal != fechaActual) {
                                    return false;
                                }
                            }
                            if (fecha == "ayer") {
                                if (
                                    moment(fechaVal).date() !=
                                    moment(fechaActual).date() - 1
                                ) {
                                    return false;
                                }
                            }
                            if (fecha == "7dias") {
                                if (
                                    moment(val.timestamp).isBefore(
                                        moment(
                                            new Date().getTime() - 84000000 * 8
                                        )
                                    )
                                ) {
                                    return false;
                                }
                            }
                            if (fecha == "14dias") {
                                if (
                                    moment(val.timestamp).isBefore(
                                        moment(
                                            new Date().getTime() - 84000000 * 14
                                        )
                                    )
                                ) {
                                    return false;
                                }
                            }
                        }
                    }
                    
                    if (val.date_sale) {
                        let fechaVal = moment(val.date_sale).format(
                            "YYYY-MM-DD"
                        );
                        let fechaActual = moment().format("YYYY-MM-DD");

                        for (let fecha of dateFilter) {
                            if (fecha == "hoy") {
                                if (fechaVal != fechaActual) {
                                    return false;
                                }
                            }
                            if (fecha == "ayer") {
                                if (
                                    moment(fechaVal).date() !=
                                    moment(fechaActual).date() - 1
                                ) {
                                    return false;
                                }
                            }
                            if (fecha == "7dias") {
                                if (
                                    moment(val.date_sale).isBefore(
                                        moment(
                                            new Date().getTime() - 84000000 * 8
                                        )
                                    )
                                ) {
                                    return false;
                                }
                            }
                            if (fecha == "14dias") {
                                if (
                                    moment(val.date_sale).isBefore(
                                        moment(
                                            new Date().getTime() - 84000000 * 14
                                        )
                                    )
                                ) {
                                    return false;
                                }
                            }
                        }
                    }
                    if (val.date) {
                        let fechaVal = moment(val.date).format("YYYY-MM-DD");
                        let fechaActual = moment().format("YYYY-MM-DD");

                        for (let fecha of dateFilter) {
                            if (fecha == "hoy") {
                                if (fechaVal != fechaActual) {
                                    return false;
                                }
                            }
                            if (fecha == "ayer") {
                                if (
                                    moment(fechaVal).date() !=
                                    moment(fechaActual).date() - 1
                                ) {
                                    return false;
                                }
                            }
                            if (fecha == "7dias") {
                                if (
                                    moment(val.date).isBefore(
                                        moment(
                                            new Date().getTime() - 84000000 * 8
                                        )
                                    )
                                ) {
                                    return false;
                                }
                            }
                            if (fecha == "14dias") {
                                if (
                                    moment(val.date).isBefore(
                                        moment(
                                            new Date().getTime() - 84000000 * 14
                                        )
                                    )
                                ) {
                                    return false;
                                }
                            }
                        }
                    }
                }

                return true;
            });
            setListTable(aux);
            // ====================================
        }, [
            agentFilter,
            cierresFilter,
            anteriorFilter,
            fuentesFilter,
            statusFilter,
            vendedorFilter,
            originFilter,
            dateFilter,
            searched,
            viasFilter,
        ]);

        return (
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        "& .header-meta, .MuiDataGrid-columnsContainer": {
                            backgroundColor: "#0080ff",
                            color: "#fff",
                        },
                        "& .header-meta .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: 600,
                        },
                        "& .MuiDataGrid-columnHeaderWrapper ": {
                            height: 20,
                        },
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: 900,
                        maxHeight: 800,
                        height: "100%",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: "15px",
                        p: 2,
                    }}
                >
                    <Grid
                        container
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Grid item>
                            <Typography fontSize={24}>{title}</Typography>
                        </Grid>
                        <Grid
                            item
                            alignSelf="center"
                            sx={{
                                "&:hover": {
                                    color: "#3d9ffb",
                                },
                            }}
                        >
                            <Close
                                sx={{ fontSize: "28px" }}
                                onClick={handleClose}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            "@media (max-width: 479px)": { display: "block" },
                            display: "none",
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{ my: 2, textTransform: "none" }}
                            onClick={() =>
                                toggleFilter(
                                    showFilter == "none" ? "block" : "none"
                                )
                            }
                            endIcon={<FilterAlt />}
                        >
                            Mostrar filtros
                        </Button>
                    </Grid>
                    <Grid
                        direction={"column"}
                        py={1}
                        sx={{
                            "@media (max-width: 479px)": {
                                display: showFilter,
                            },
                        }}
                    >
                        <Paper
                            component="form"
                            sx={{
                                p: "2px 4px",
                                display: "inline-flex",
                                alignItems: "center",
                                width: "200px",
                                height: "45px",
                                mr: 2,
                                mt: 0.5,
                                "@media (min-width: 480px)": { mb: 1 },
                            }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                onChange={(searchVal: any) =>
                                    setSearched(searchVal.target.value)
                                }
                                placeholder="Buscar..."
                                inputProps={{ "aria-label": "buscar" }}
                            />
                            <IconButton
                                type="submit"
                                sx={{ p: "5px", mr: 0.5 }}
                                aria-label="search"
                            >
                                <SearchIcon />
                            </IconButton>
                        </Paper>

                        {filters && filters.includes("Fecha") && (
                            <FormControl
                                sx={{
                                    width: "200px",
                                    mr: 2,
                                    mt: 1,
                                    "@media (max-width: 479px)": {
                                        mt: 2,
                                        mb: 1,
                                    },
                                }}
                                size="small"
                            >
                                <InputLabel>Fecha</InputLabel>
                                <Select
                                    label="Fecha"
                                    onChange={(event) =>
                                        setDateFilter([
                                            event.target.value as string,
                                        ])
                                    }
                                    value={dateFilter}
                                >
                                    <MenuItem value="hoy">Hoy</MenuItem>
                                    <MenuItem value="ayer">Ayer</MenuItem>
                                    <MenuItem value="7dias">
                                        Últimos 7 días
                                    </MenuItem>
                                    <MenuItem value="14dias">
                                        Últimos 14 días
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        )}

                        {filters && filters.includes("Agente") && (
                            <FilterMultiSelect
                                title="Agente"
                                list={agentes.map((agente) => {
                                    return { name: agente, code: agente };
                                })}
                                idKey={"code"}
                                setValue={(value) => setAgentFilter(value)}
                                getName={(value) => value.name}
                                value={agentFilter}
                            />
                        )}

                        {filters && filters.includes("Anterior") && (
                            <FilterMultiSelect
                                title="Cierre Anterior"
                                list={anterior.map((agente) => {
                                    return { name: agente, code: agente };
                                })}
                                idKey={"code"}
                                setValue={(value) => setAnteriorFilter(value)}
                                getName={(value) => value.name}
                                value={anteriorFilter}
                            />
                        )}

                        {filters && filters.includes("Cierre") && (
                            <FilterMultiSelect
                                title="Cierre"
                                list={cierres.map((agente) => {
                                    return { name: agente, code: agente };
                                })}
                                idKey={"code"}
                                setValue={(value) => setCierresFilter(value)}
                                getName={(value) => value.name}
                                value={cierresFilter}
                            />
                        )}

                        {filters && filters.includes("Vendedor") && (
                            <FilterMultiSelect
                                title="Vendedor"
                                list={vendedores.map((vendedor) => {
                                    return { name: vendedor, code: vendedor };
                                })}
                                idKey={"code"}
                                setValue={(value) => setVendedorFilter(value)}
                                getName={(value) => value.name}
                                value={vendedorFilter}
                            />
                        )}

                        {filters && filters.includes("Origen") && (
                            <FilterMultiSelect
                                title="Origen"
                                list={origenes.map((origen) => {
                                    return { name: origen, code: origen };
                                })}
                                idKey={"code"}
                                setValue={(value) => setOiriginFilter(value)}
                                getName={(value) => value.name}
                                value={originFilter}
                            />
                        )}

                        {filters && filters.includes("Via") && (
                            <FilterMultiSelect
                                title="Via"
                                list={vias.map((via) => {
                                    return {
                                        name:
                                            via[0].toUpperCase() +
                                            via.substring(1),
                                        code: via,
                                    };
                                })}
                                idKey={"code"}
                                setValue={(value) => setViasFilter(value)}
                                getName={(value) => value.name}
                                value={viasFilter}
                            />
                        )}

                        {filters && filters.includes("Estado") && (
                            <FilterMultiSelect
                                title="Estado"
                                list={estados.map((estado) => {
                                    return { name: estado, code: estado };
                                })}
                                idKey={"code"}
                                setValue={(value) => setStatusFilter(value)}
                                getName={(value) => value.name}
                                value={statusFilter}
                            />
                        )}

                        {filters && filters.includes("Fuente") && (
                            <FilterMultiSelect
                                title="Fuente"
                                list={fuentes.map((fuente) => {
                                    return { name: fuente, code: fuente };
                                })}
                                idKey={"code"}
                                setValue={(value) => setFuenteFilter(value)}
                                getName={(value) => value.name}
                                value={fuentesFilter}
                            />
                        )}

                        {filters && filters.includes("TipoLlamada") && (
                            <FilterMultiSelect
                                title="Tipo"
                                list={[
                                    { name: "Saliente", code: "OUTGOING" },
                                    { name: "Perdida", code: "MISSED" },
                                    { name: "Desconocido", code: "UNKNOWN" },
                                    { name: "Entrante", code: "INCOMING" },
                                ]}
                                idKey={"code"}
                                setValue={(value) => setFuenteFilter(value)}
                                getName={(value) => value.name}
                                value={fuentesFilter}
                            />
                        )}
                    </Grid>
                    <DataGrid
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        rowHeight={32}
                        headerHeight={32}
                        pageSize={25}
                        rowsPerPageOptions={[25]}
                        columns={columns}
                        rows={listTable.map((i, index) => ({
                            ...i,
                            id: i._id ? i._id : index + i,
                        }))}
                    />
                </Box>
            </Modal>
        );
    };

    return (
        <>
            <ModalList />

            <Grid
                container
                alignItems={"center"}
                sx={{
                    "&:hover": { background: "#dceefe" },
                    borderRadius: "10px",
                    p: 1,
                }}
                onClick={() => setOpen(!open)}
            >
                <Grid
                    item
                    container
                    alignItems={"center"}
                    flexWrap={"nowrap"}
                    xs={12}
                    md={4}
                    mb={{ xs: 1, md: 0 }}
                >
                    {icon}
                    <Typography
                        sx={{ fontSize: "13px", fontWeight: "600", mx: 1 }}
                    >
                        {title}
                    </Typography>
                </Grid>
                <Grid
                    item
                    sx={{
                        width: "70px",
                        background: "#fafafa",
                        borderRadius: "4px",
                        border: "1px solid #efefef",
                        mr: 2,
                    }}
                >
                    <Chart options={options} series={series} type="bar"></Chart>
                </Grid>
                <Grid item>
                    <Grid container>
                        <Grid alignItems={"left"} sx={{ height: "100%" }}>
                            <Grid
                                item
                                container
                                alignItems={"center"}
                                flexWrap={"nowrap"}
                            >
                                <Typography
                                    sx={{ fontSize: "18px", fontWeight: "700" }}
                                >
                                    {values.today}
                                </Typography>
                                <ChipValue
                                    title={title}
                                    value={values.today}
                                    compareValue={values.yesterday}
                                    valueDateTitle={usarAyer ? "ayer" : "hoy"}
                                    compareValueDateTitle={
                                        usarAyer ? "ante ayer" : "ayer"
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography
                                    sx={{
                                        color: "#999999",
                                        fontSize: "12px",
                                        fontWeight: "600",
                                    }}
                                >
                                    {usarAyer ? "Ayer" : "Hoy"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item ml={"auto"}>
                    <Grid container>
                        <Grid alignItems={"left"} sx={{ height: "100%" }}>
                            <Grid
                                item
                                container
                                alignItems={"center"}
                                justifyContent={"flex-end"}
                                flexWrap={"nowrap"}
                            >
                                <ChipValue
                                    title={title}
                                    value={values.week}
                                    compareValue={values.prev_week}
                                    valueDateTitle={
                                        usarAyer
                                            ? "últimos 7 días desde ayer"
                                            : "últimos 7 días"
                                    }
                                    compareValueDateTitle={
                                        usarAyer
                                            ? "días 9 a 15 desde hoy"
                                            : "días 8 a 14 desde hoy"
                                    }
                                />
                                <Typography
                                    sx={{ fontSize: "18px", fontWeight: "700" }}
                                >
                                    {Math.floor((values.week / 7) * 10) / 10}
                                    <span
                                        style={{
                                            color: "#999999",
                                            fontSize: "12px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        /d
                                    </span>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    sx={{
                                        color: "#999999",
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        textAlign: "right",
                                    }}
                                >
                                    promedio 7 días
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

GraphItem.defaultProps = {};

export default GraphItem;
