


    interface alertasInterface {
        title: string,
        indicatorTitle: string,
        user_titles?: string[],
        description: string,
        color: "default" | "error" | "success" | "primary" | "secondary" | "info" | "warning",
        icon: string
    }

export const getColorByLevel = (level: number) => {
    switch(level){
        case 0: 
            return 'error'
        case 1:
            return 'warning'
        case 2:
            return 'info'
        case 3:
            return 'success'
        case 4:
            return 'secondary'
    }
    return 'info'
}

export const AlertMap: (key:string, level: number) => alertasInterface = (key:string, level: number) => {
    


    switch(key){
        case 'contact_creation_consistency':

            return {
                title: "Habito de crear contactos",
                user_titles: [
                    "No creaste contactos esta semana",
                    "Creaste contactos 1-2 días esta semana",
                    "Creaste contactos 1-2 días esta semana",
                    "Creaste contactos 1-2 días esta semana",
                    "Creaste contactos 1-2 días esta semana",
                ],
                description:"Tienes una muy buena constancia de contactos",
                color: 'error',
                indicatorTitle: "Habito de crear contactos",
                icon: 'contacts'
            }
        case 'contact_creation_days':
            return {
                title: "+3 dias sin cargar contactos",
                description: "Tienes una muy buena constancia de contactos",
                color: 'error',
                indicatorTitle: "Dias sin cargar contactos",
                icon: 'contacts'
            }
            
            case 'disregarded_contacts':
                return {
                    title: "Bandeja contactos desatendidos",
                    description: "Tienes una muy buena constancia de contactos",
                    color: getColorByLevel(level),
                    indicatorTitle: "Contactos desatendidos",
                    icon: 'contacts'
                }
            case 'first_login':
                return {
                    title: "No ha entrado a Ventia",
                    description: "Tienes una muy buena constancia de contactos",
                    color: getColorByLevel(level),
                    indicatorTitle: "No ha entrado a Ventia",
                    icon: 'login'
                }
            case 'tracing_creation_consistency':
                return {
                    title: "Consistencia de seguimientos",
                    description: "Tienes una muy buena constancia de contactos",
                    color: getColorByLevel(level),
                    indicatorTitle: "Habito de seguimientos",
                    icon: 'phone'
                }
            case 'usage':
                return {
                    title: "Uso de Ventia",
                    description: "Tienes una muy buena constancia de contactos",
                    color: getColorByLevel(level),
                    indicatorTitle: "Uso de Ventia",
                    icon: 'usage'
                }
            case 'reminders_consistency':
                return {
                    title: "Uso de recordatorio",
                    description: "Tienes una muy buena constancia de contactos",
                    color: getColorByLevel(level),
                    indicatorTitle: "Habito de Recordatorios",
                    icon: 'reminder'
                }
            case 'notes_consistency':
                return {
                    title: "Notas",
                    description: "Tienes una muy buena constancia de contactos",
                    color: getColorByLevel(level),
                    indicatorTitle: "Habito de Notas",
                    icon: 'note'
                }
            case 'call_registry_consistency':
                return {
                    title: "Habito de llamadas",
                    description: "Tienes una muy buena constancia de contactos",
                    color: getColorByLevel(level),
                    indicatorTitle: "Habito de llamadas",
                    icon: 'phone'
                }
            case 'last_call_registry':
                return {
                    title: "Subida de llamadas en Ventia",
                    description: "Tienes una muy buena constancia de contactos",
                    color: getColorByLevel(level),
                    indicatorTitle: "Subida de llamadas en Ventia",
                    icon: 'usage'
                }

            case 'objective_sales':
                return {
                    title: "Objetivo de ventas",
                    description: "Tienes una muy buena constancia de contactos",
                    color: getColorByLevel(level),
                    indicatorTitle: "Objetivo de ventas",
                    icon: 'sale'
                }
            case 'objective_tracing':
                return {
                    title: "Objetivo de seguimientos",
                    description: "Tienes una muy buena constancia de contactos",
                    color: getColorByLevel(level),
                    indicatorTitle: "Objetivo de seguimientos",
                    icon: 'phone'
                }
            case 'objective_contact':
                return {
                    title: "Objetivo de contactos",
                    description: "Tienes una muy buena constancia de contactos",
                    color: getColorByLevel(level),
                    indicatorTitle: "Objetivo de contactos",
                    icon: 'contacts'
                }

            case 'visit_consistency':
                return {
                    title: "Habito de visitas",
                    description: "Tienes una muy buena constancia de contactos",
                    color: getColorByLevel(level),
                    indicatorTitle: "Habito de visitas",
                    icon: 'visit'
                }

        default:
        
    }
    return {
        title: "Alerta desconocida - "+key,
        description: "Alerta desconocida",
        color: getColorByLevel(level),
        indicatorTitle: "Alerta desconocida "+key,
        icon: 'info'
    }
}