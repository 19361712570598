import { Chip, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import _ from "lodash";
import { useMemo } from "react";
import { getCompany } from "../../../actions/auth";
import AdvancedModal from "../../core/AdvancedModal"
import { IconLevel } from "./AlertCardGroup";
import { AlertMap } from "./AlertMap";


interface AlertAllModalInterface {
    open: false | string,
    handleClose: () => void;
    userAlerts: any[]
}

const AlertShowUsersModal = ({open, handleClose, userAlerts}:AlertAllModalInterface) => {

    let alerta = useMemo(() => open ? AlertMap(open, 0) : {title: ''}, [open])

    const [users, usersLevel] = useMemo(() => {
        if(!open || !userAlerts) return [[],[]]
        let usersByLevel = [[],[],[],[],[]]
        userAlerts.map((user) => {
            let alert = user.alerts.filter((a) => a.alert == open )
            if(alert.length> 0){
                usersByLevel[alert[0].level].push({...user.user, level: alert[0].level})
            }
        })

        let total = []
        usersByLevel.map((usrs) => total = [...total, ...usrs])

        return [total, usersByLevel]

    }, [userAlerts, open])

    return <AdvancedModal
        title={<>Alerta <Chip label={alerta.title} sx={{fontWeight: 600, ml: 2}} /></>}
        open={!!open}
        handleClose={handleClose}
        expandMax={true}
        size={"large"}
        sxContainer={{height: '90%'}}
    >
        <Grid container sx={{width: '100%', height: '100%'}}>
            <DataGrid
                rows={users.map((u:any) => ({id: u._id, ...u}))}
                columns={[
                    { field: 'level', headerName: '', width: 50, renderCell: (params) => {
                        return <IconLevel level={params.value} sx={{fontSize: 22}}/>
                    } },
                    { field: 'name', headerName: 'Nombre', width: 150 },
                    { field: 'lastname', headerName: 'Apellido', width: 150 },
                    ..._.get(getCompany(), 'additionalUserFields', []).map((field) => ({
                        field: "agent_additionaData_"+field.key,
                        headerName: field.title,
                        description: field.title,
                        width: 200,
                        renderCell: (params) => {
                            return _.get(params.row, 'additionalData.'+field.key, '')
                        }
                    }))
                ]}
                sx={{width: '100%', height: '100%'}}
                pageSize={100}
                rowsPerPageOptions={[100]}
                disableColumnMenu={true}
            />
        </Grid>


    </AdvancedModal>
}

export default AlertShowUsersModal