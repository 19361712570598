import { Chip } from "@mui/material";
import { BasicUser, InterfaceTypeComplete, evaluationRating, evaluationKeys } from '../../interfaces/Interface'



interface EvaluationChipInterface {
    rating: number,
    keyCode: string
}

const EvaluationChip = ({rating, keyCode}:EvaluationChipInterface) => (
<Chip 
    variant="outlined"
    size="small" 
    sx={{ p: 0, mr: 1, mt: 0.5, borderWidth: '2px' }} 
    icon={evaluationRating[rating].icon.type.render()} 
    label={evaluationKeys[keyCode]} 
    color={evaluationRating[rating].color}></Chip>

)


export default EvaluationChip