import { useEffect, useState } from "react";
import TabPanelNativa from "../../components/TabPanelNativa";
import {
    isAuth,
    reloadtoken,
    authenticate,
    getVarUrl,
    getCompany,
} from "../../actions/auth";
import Linkify from 'react-linkify';
import TextPanel from "../../components/TextPanel";
import Performance from "../goal/Performance";
import {
    setUsers,
    setSelectedUser,
    setUsarAyer,
} from "../../redux/actions/main";
import { connect } from "react-redux";
import PanelInfo from "./panel_info";
import {
    Alert,
    Grid,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import Module from "../core/Module";
import InstanceListModule from "../evaluate/InstanceListModule";
import {
    Call,
    Contacts,
    PhoneInTalk,
    Sell,
    RecordVoiceOver,
    Inventory2,
    TransferWithinAStation,
    CloudUpload,
    Error as ErrorIcon,
} from "@mui/icons-material";
import GraphItem from "./GraphItem";
import moment from "moment";
import Goal from "../goal/Goal";
import StatusCard from "../StatusCard";
import NextLink from "next/link";
import Trophy from "../goal/Trophy";
import _ from "lodash";
import AlertReport from "../goal/alert/AlertUserReport";
import AlertGroupReport from "../goal/alert/AlertGroupReport";
import PerformanceContent from "../goal/Performance/PerformanceContent";

function Home({ selectedUser, selectedGroup, setUsarAyer, usarAyer }) {
    var [usuario, setUsuario] = useState<any>({});
    var [vendedor, setVendedor] = useState("");
    var [grupo, setGrupo] = useState({});
    var [rol, setRol] = useState<string | undefined>();
    var [panel] = useState(PanelInfo);
    const [showAvisos, setShowAvisos] = useState(true)

    useEffect(() => {
        let user = isAuth();
        let groupi;
        if (user) {
            setUsuario(user);
            if (user.roles[0] == "admin") setRol("comercial");
            if (user.roles[0] != "admin") {
                if (user.roles.includes('comercial')) {
                    setRol('comercial');
                } else if (user.roles.includes('super')) {
                    setRol('super');
                } else {
                    setRol(user.roles[0]);
                }
            }
            setVendedor(user.role == 0 ? user : {});
            if (user.group.ancestors.length > 0) {
                setGrupo(user.group.ancestors[0]);
                groupi = user.group.ancestors[0];
            } else {
                setGrupo(user.group);
                groupi = user.group;
            }
            reloadtoken().then((res) => {
                if (!res || res.error) return;
                if (res.user.group.ancestors.length > 0) {
                    setGrupo(res.user.group.ancestors[0]);
                } else {
                    setGrupo(res.user.group);
                }
                return authenticate(res, () => { });
            });
        }
    }, []);

    return (
        <Grid sx={{ background: "#f6f6f6" }}>
            <Grid
                container
                rowSpacing={2}
                direction="column"
                p={{ sm: 2 }}
                mx={"auto"}
                sx={{ width: 1, maxWidth: 1200, background: "#f6f6f6" }}
            >
                {panel
                    .map((p) => {
                        if (
                            selectedUser
                                ? p.role.indexOf("user") < 0
                                : selectedGroup
                                    ? p.role.indexOf("super") < 0
                                    : p.role.indexOf(rol) < 0
                        )
                            return "";
                        switch (p.template) {
                            case "text":
                                return (
                                    <TextPanel
                                        key={p._id}
                                        title={p.title}
                                        user={
                                            selectedUser
                                                ? selectedUser
                                                : usuario
                                        }
                                    >
                                        {p.content.text}
                                    </TextPanel>
                                );
                            case "report":
                                return (
                                    <Module title={p.title}>
                                        <iframe
                                            frameBorder="0"
                                            width="100%"
                                            height={p.content.height}
                                            src={getVarUrl(
                                                p.content.url,
                                                selectedUser
                                                    ? selectedUser
                                                    : usuario
                                            )}
                                        ></iframe>
                                    </Module>
                                );
                            case "long":

                                let user_usando_per_alert = selectedUser
                                    ? selectedUser._id
                                    : rol == "super" || rol == "comercial"
                                        ? undefined
                                        : usuario._id;
                                let groupToShow = selectedGroup
                                    ? selectedGroup._id
                                    : selectedUser ? undefined : usuario.group._id;



                                return (
                                    <Grid
                                        container
                                        direction={"row"}
                                        spacing={2}
                                        alignItems="stretch"
                                        justifyContent={"stretch"}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            lg={6}
                                            sx={{ height: "100%" }}
                                        >
                                            {
                                                showAvisos ?
                                                    <InstanceListModule
                                                        groupFilter={
                                                            selectedGroup
                                                                ? selectedGroup._id :
                                                                undefined
                                                        }
                                                        clickedAvisos={() => setShowAvisos(false)}
                                                        id={
                                                            selectedUser
                                                                ? selectedUser._id :
                                                                rol == "super" ||
                                                                    rol == "comercial"
                                                                    ? "all" :
                                                                    usuario._id
                                                        }
                                                    /> :
                                                    groupToShow ?
                                                        <AlertGroupReport
                                                            clickedAvisos={() => setShowAvisos(true)}
                                                            group={groupToShow}
                                                        />
                                                        :
                                                        <AlertReport
                                                            clickedAvisos={() => setShowAvisos(true)}
                                                            user={user_usando_per_alert}
                                                        />
                                            }

                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <Module
                                                title={p.title}
                                                sx={{ height: "100%" }}
                                                action={
                                                    <ToggleButtonGroup
                                                        value={
                                                            usarAyer
                                                                ? "si"
                                                                : "no"
                                                        }
                                                        exclusive
                                                    >
                                                        <ToggleButton
                                                            value={"si"}
                                                            onClick={() =>
                                                                setUsarAyer(
                                                                    false
                                                                )
                                                            }
                                                            sx={{
                                                                py: 0.25,
                                                                "&:hover": {
                                                                    color: "white",
                                                                    borderColor:
                                                                        "#2b7cd2",
                                                                    background:
                                                                        "#2b7cd2",
                                                                },
                                                                color: usarAyer
                                                                    ? "#0080ff !important"
                                                                    : "white",
                                                                borderColor:
                                                                    usarAyer
                                                                        ? "#0080ff"
                                                                        : "#0080ff",
                                                                background:
                                                                    usarAyer
                                                                        ? "#fff !important"
                                                                        : "#0080ff",
                                                            }}
                                                        >
                                                            Hoy
                                                        </ToggleButton>
                                                        <ToggleButton
                                                            value={"no"}
                                                            onClick={() =>
                                                                setUsarAyer(
                                                                    true
                                                                )
                                                            }
                                                            sx={{
                                                                py: 0.25,
                                                                "&:hover": {
                                                                    color: "white",
                                                                    borderColor:
                                                                        "#2b7cd2",
                                                                    background:
                                                                        "#2b7cd2",
                                                                },
                                                                color: !usarAyer
                                                                    ? "#0080ff !important"
                                                                    : "white",
                                                                borderColor:
                                                                    !usarAyer
                                                                        ? "#0080ff"
                                                                        : "#0080ff",
                                                                background:
                                                                    !usarAyer
                                                                        ? "#fff !important"
                                                                        : "#0080ff",
                                                            }}
                                                        >
                                                            Ayer
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                }
                                            >
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <GraphItem
                                                            filters={[
                                                                "Fecha",
                                                                "Agente",
                                                                "Origen",
                                                            ]}
                                                            icon={<Contacts />}
                                                            title="Contactos creados"
                                                            source="manual_contacts"
                                                            dateField="created"
                                                            userId={
                                                                selectedUser
                                                                    ? selectedUser._id
                                                                    : rol ==
                                                                        "super" ||
                                                                        rol ==
                                                                        "comercial"
                                                                        ? "all"
                                                                        : usuario._id
                                                            }
                                                            groupId={
                                                                selectedGroup
                                                                    ? selectedGroup._id
                                                                    : undefined
                                                            }
                                                            columns={[
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "created",
                                                                    headerName:
                                                                        "Fecha y hora",
                                                                    description:
                                                                        "Fecha y hora de creación",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return (
                                                                                moment(
                                                                                    new Date(
                                                                                        params.value
                                                                                    )
                                                                                ).format(
                                                                                    "L"
                                                                                ) +
                                                                                " " +
                                                                                moment(
                                                                                    new Date(
                                                                                        params.value
                                                                                    )
                                                                                ).format(
                                                                                    "LT"
                                                                                )
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "phones",
                                                                    headerName:
                                                                        "Telefono",
                                                                    description:
                                                                        "Telefono del agente",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params
                                                                                .value[0];
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "agent",
                                                                    headerName:
                                                                        "Vendedor",
                                                                    description:
                                                                        "Nombre del agente",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return `${params.value.firstName} ${params.value.lastName}`;
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "firstName",
                                                                    headerName:
                                                                        "Nombre contacto",
                                                                    description:
                                                                        "Nombre del contacto",
                                                                    width: 150,
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "lastName",
                                                                    headerName:
                                                                        "Apellido Contacto",
                                                                    description:
                                                                        "Apellido del contacto",
                                                                    width: 150,
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "leads",
                                                                    headerName:
                                                                        "Orgien",
                                                                    description:
                                                                        "Grupo del contacto",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            let res =
                                                                                params
                                                                                    .value[0]
                                                                                    .source;
                                                                            res =
                                                                                res[0].toUpperCase() +
                                                                                res
                                                                                    .substr(
                                                                                        1
                                                                                    )
                                                                                    .toLowerCase();
                                                                            res.slice(
                                                                                1,
                                                                                1
                                                                            );
                                                                            return res;
                                                                        },
                                                                },
                                                            ]}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <GraphItem
                                                            icon={<Call />}
                                                            filters={[
                                                                "Fecha",
                                                                "Vendedor",
                                                                "TipoLlamada",
                                                            ]}
                                                            title="Llamadas"
                                                            source="calls"
                                                            dateField="timestamp"
                                                            userId={
                                                                selectedUser
                                                                    ? selectedUser._id
                                                                    : rol ==
                                                                        "super" ||
                                                                        rol ==
                                                                        "comercial"
                                                                        ? "all"
                                                                        : usuario._id
                                                            }
                                                            groupId={
                                                                selectedGroup
                                                                    ? selectedGroup._id
                                                                    : undefined
                                                            }
                                                            columns={[
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "timestamp",
                                                                    headerName:
                                                                        "Fecha y hora",
                                                                    description:
                                                                        "Fecha y hora",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return (
                                                                                moment(
                                                                                    new Date(
                                                                                        parseInt(
                                                                                            params.value
                                                                                        )
                                                                                    )
                                                                                ).format(
                                                                                    "L"
                                                                                ) +
                                                                                " " +
                                                                                moment(
                                                                                    new Date(
                                                                                        parseInt(
                                                                                            params.value
                                                                                        )
                                                                                    )
                                                                                ).format(
                                                                                    "LT"
                                                                                )
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "phoneNumber",
                                                                    headerName:
                                                                        "Telefono",
                                                                    description:
                                                                        "Telefono",
                                                                    width: 150,
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "prospectName",
                                                                    headerName:
                                                                        "Nombre Contacto",
                                                                    description:
                                                                        "Nombre Contacto",
                                                                    width: 150,
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "duration",
                                                                    headerName:
                                                                        "Duracion",
                                                                    description:
                                                                        "Duracion en minutos de la llamada",
                                                                    width: 150,

                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            let dateObj =
                                                                                new Date(
                                                                                    params.value *
                                                                                    1000
                                                                                );
                                                                            let hours =
                                                                                dateObj.getUTCHours();
                                                                            let minutes =
                                                                                dateObj.getUTCMinutes();
                                                                            let seconds =
                                                                                dateObj.getSeconds();

                                                                            let timeString =
                                                                                hours
                                                                                    .toString()
                                                                                    .padStart(
                                                                                        2,
                                                                                        "0"
                                                                                    ) +
                                                                                ":" +
                                                                                minutes
                                                                                    .toString()
                                                                                    .padStart(
                                                                                        2,
                                                                                        "0"
                                                                                    ) +
                                                                                ":" +
                                                                                seconds
                                                                                    .toString()
                                                                                    .padStart(
                                                                                        2,
                                                                                        "0"
                                                                                    );

                                                                            return timeString;
                                                                        },
                                                                },

                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "type",
                                                                    headerName:
                                                                        "Tipo",
                                                                    description:
                                                                        "Tipo",
                                                                    width: 100,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            let value =
                                                                                "";
                                                                            switch (
                                                                            params.value
                                                                            ) {
                                                                                case "INCOMING":
                                                                                    value =
                                                                                        "Entrante";
                                                                                    break;
                                                                                case "MISSED":
                                                                                    value =
                                                                                        "Perdida";
                                                                                    break;
                                                                                case "UNKNOWN":
                                                                                    value =
                                                                                        "Desconocida";
                                                                                    break;
                                                                                case "OUTGOING":
                                                                                    value =
                                                                                        "Saliente";
                                                                                    break;
                                                                            }
                                                                            return value;
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "user",
                                                                    headerName:
                                                                        "Vendedor",
                                                                    description:
                                                                        "Vendedor",
                                                                    width: 200,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return (
                                                                                params
                                                                                    .value
                                                                                    .name +
                                                                                " " +
                                                                                params
                                                                                    .value
                                                                                    .lastname
                                                                            );
                                                                        },
                                                                },
                                                            ]}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <GraphItem
                                                            groupId={
                                                                selectedGroup
                                                                    ? selectedGroup._id
                                                                    : undefined
                                                            }
                                                            columns={[
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "timestamp",
                                                                    headerName:
                                                                        "Fecha",
                                                                    description:
                                                                        "Fecha",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return new Date(
                                                                                params.value
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "phoneNumber",
                                                                    headerName:
                                                                        "Telefono",
                                                                    description:
                                                                        "Telefono",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params.value;
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "timestamp",
                                                                    headerName:
                                                                        "Duracion",
                                                                    description:
                                                                        "Duracion",
                                                                    width: 100,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            // let dateObj = new Date(params.value * 1000);
                                                                            // let hours = dateObj.getUTCHours();
                                                                            // let minutes = dateObj.getUTCMinutes();
                                                                            // let seconds = dateObj.getSeconds();

                                                                            // let timeString = hours.toString().padStart(2, '0') + ':' +
                                                                            //     minutes.toString().padStart(2, '0') + ':' +
                                                                            //     seconds.toString().padStart(2, '0');

                                                                            // return timeString;
                                                                            return (
                                                                                params.value +
                                                                                ""
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "user",
                                                                    headerName:
                                                                        "Nombre",
                                                                    description:
                                                                        "Nombre",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return (
                                                                                params
                                                                                    .value
                                                                                    .name +
                                                                                " " +
                                                                                params
                                                                                    .value
                                                                                    .lastname
                                                                            );
                                                                        },
                                                                },
                                                            ]}
                                                            icon={
                                                                <PhoneInTalk />
                                                            }
                                                            filters={[
                                                                "Fecha",
                                                                "Vendedor",
                                                            ]}
                                                            title="Minutos al aire"
                                                            source="calls"
                                                            action="sum"
                                                            field="duration"
                                                            dateField="timestamp"
                                                            divide={60}
                                                            userId={
                                                                selectedUser
                                                                    ? selectedUser._id
                                                                    : rol ==
                                                                        "super" ||
                                                                        rol ==
                                                                        "comercial"
                                                                        ? "all"
                                                                        : usuario._id
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <GraphItem
                                                            groupId={
                                                                selectedGroup
                                                                    ? selectedGroup._id
                                                                    : undefined
                                                            }
                                                            columns={[
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "date",
                                                                    headerName:
                                                                        "Fecha",
                                                                    description:
                                                                        "Fecha",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return moment(
                                                                                new Date(
                                                                                    params.value
                                                                                )
                                                                            ).format(
                                                                                "L"
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "phone",
                                                                    headerName:
                                                                        "Telefono",
                                                                    description:
                                                                        "Telefono",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params.value;
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "maxDuration",
                                                                    headerName:
                                                                        "Duracion",
                                                                    description:
                                                                        "Duracion",
                                                                    width: 100,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            let dateObj =
                                                                                new Date(
                                                                                    params.value *
                                                                                    1000
                                                                                );
                                                                            let hours =
                                                                                dateObj.getUTCHours();
                                                                            let minutes =
                                                                                dateObj.getUTCMinutes();
                                                                            let seconds =
                                                                                dateObj.getSeconds();

                                                                            let timeString =
                                                                                hours
                                                                                    .toString()
                                                                                    .padStart(
                                                                                        2,
                                                                                        "0"
                                                                                    ) +
                                                                                ":" +
                                                                                minutes
                                                                                    .toString()
                                                                                    .padStart(
                                                                                        2,
                                                                                        "0"
                                                                                    ) +
                                                                                ":" +
                                                                                seconds
                                                                                    .toString()
                                                                                    .padStart(
                                                                                        2,
                                                                                        "0"
                                                                                    );

                                                                            return timeString;
                                                                        },
                                                                },

                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "validity",
                                                                    headerName:
                                                                        "Valido",
                                                                    description:
                                                                        "Valido",
                                                                    width: 100,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            if (
                                                                                params.value
                                                                            ) {
                                                                                return "Valido";
                                                                            } else {
                                                                                return "Invalido";
                                                                            }
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "user",
                                                                    headerName:
                                                                        "Vendedor",
                                                                    description:
                                                                        "Nombre",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return (
                                                                                params
                                                                                    .value
                                                                                    .name +
                                                                                " " +
                                                                                params
                                                                                    .value
                                                                                    .lastname
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "noteContent",
                                                                    headerName:
                                                                        "Nota",
                                                                    description:
                                                                        "Nota",
                                                                    width: 200,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params.value;
                                                                        },
                                                                },
                                                            ]}
                                                            icon={
                                                                <RecordVoiceOver />
                                                            }
                                                            filters={[
                                                                "Fecha",
                                                                "Vendedor",
                                                                "Duracion",
                                                            ]}
                                                            format={
                                                                "YYYY/MM/DD"
                                                            }
                                                            title="Seguimientos"
                                                            source="tracing"
                                                            dateField="date"
                                                            userId={
                                                                selectedUser
                                                                    ? selectedUser._id
                                                                    : rol ==
                                                                        "super" ||
                                                                        rol ==
                                                                        "comercial"
                                                                        ? "all"
                                                                        : usuario._id
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <GraphItem
                                                            groupId={
                                                                selectedGroup
                                                                    ? selectedGroup._id
                                                                    : undefined
                                                            }
                                                            columns={[
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "date_sale",
                                                                    headerName:
                                                                        "Fecha de venta",
                                                                    description:
                                                                        "Fecha de la venta",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return moment(
                                                                                new Date(
                                                                                    params.value
                                                                                )
                                                                            ).format(
                                                                                "L"
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "date",
                                                                    headerName:
                                                                        "Fecha ingreso",
                                                                    description:
                                                                        "Fecha de ingreso",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return moment(
                                                                                new Date(
                                                                                    params.value
                                                                                )
                                                                            ).format(
                                                                                "L"
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "user",
                                                                    headerName:
                                                                        "Vendedor",
                                                                    description:
                                                                        "Vendedor",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return (
                                                                                params
                                                                                    .value
                                                                                    .name +
                                                                                " " +
                                                                                params
                                                                                    .value
                                                                                    .lastname
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "ticket_id",
                                                                    headerName:
                                                                        "ID",
                                                                    description:
                                                                        "ID",
                                                                    width: 130,
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "ticket_sale_id",
                                                                    headerName:
                                                                        "ID Venta",
                                                                    description:
                                                                        "ID Venta",
                                                                    width: 130,
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "phone",
                                                                    headerName:
                                                                        "Telefono",
                                                                    description:
                                                                        "Telefono",
                                                                    width: 150,
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "sold",
                                                                    headerName:
                                                                        "Vendido",
                                                                    description:
                                                                        "Vendido",
                                                                    width: 100,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            if (
                                                                                params.value
                                                                            ) {
                                                                                return "Vendido";
                                                                            } else {
                                                                                return "No vendido";
                                                                            }
                                                                        },
                                                                },

                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "status",
                                                                    headerName:
                                                                        "Estado",
                                                                    description:
                                                                        "Estado",
                                                                    width: 100,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params.value;
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "type",
                                                                    headerName:
                                                                        "Fuente",
                                                                    description:
                                                                        "Fuente",
                                                                    width: 100,
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "name",
                                                                    headerName:
                                                                        "Nombre cliente",
                                                                    description:
                                                                        "Nombre cliente",
                                                                    width: 200,
                                                                },
                                                            ]}
                                                            icon={<Sell />}
                                                            filters={[
                                                                "Fecha",
                                                                "Vendedor",
                                                                "Estado",
                                                                "Fuente",
                                                            ]}
                                                            title="Ventas"
                                                            source="sales"
                                                            dateField="date_sale"
                                                            userId={
                                                                selectedUser
                                                                    ? selectedUser._id
                                                                    : rol ==
                                                                        "super" ||
                                                                        rol ==
                                                                        "comercial"
                                                                        ? "all"
                                                                        : usuario._id
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <GraphItem
                                                            groupId={
                                                                selectedGroup
                                                                    ? selectedGroup._id
                                                                    : undefined
                                                            }
                                                            columns={[
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "createdAt",
                                                                    headerName:
                                                                        "Fecha de cierre",
                                                                    description:
                                                                        "Fecha de cierre",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return moment(
                                                                                params.value
                                                                            ).format(
                                                                                "L LT"
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "vendedor",
                                                                    headerName:
                                                                        "Vendedor",
                                                                    description:
                                                                        "Vendedor",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return (
                                                                                params
                                                                                    .row
                                                                                    .agent_name +
                                                                                " " +
                                                                                params
                                                                                    .row
                                                                                    .agent_lastname
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "prospect_name",
                                                                    headerName:
                                                                        "Nombre",
                                                                    description:
                                                                        "Nombre",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params.value;
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "prospect_lastname",
                                                                    headerName:
                                                                        "Apellido",
                                                                    description:
                                                                        "Appellido",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params.value;
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "from",
                                                                    headerName:
                                                                        "Anterior",
                                                                    description:
                                                                        "Cierre anterior",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params.value;
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "to",
                                                                    headerName:
                                                                        "Cierre",
                                                                    description:
                                                                        "Cierre",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params.value;
                                                                        },
                                                                },
                                                            ]}
                                                            icon={
                                                                <Inventory2 />
                                                            }
                                                            filters={[
                                                                "Fecha",
                                                                "Agente",
                                                                "Anterior",
                                                                "Cierre",
                                                            ]}
                                                            title="Cierres"
                                                            source="closes"
                                                            dateField="createdAt"
                                                            userId={
                                                                selectedUser
                                                                    ? selectedUser._id
                                                                    : rol ==
                                                                        "super" ||
                                                                        rol ==
                                                                        "comercial"
                                                                        ? "all"
                                                                        : usuario._id
                                                            }
                                                        />
                                                    </Grid>
                                                    {getCompany() &&
                                                        getCompany()
                                                            .groupType ==
                                                        "facturacion" && (
                                                            <Grid item xs={12}>
                                                                <GraphItem
                                                                    groupId={
                                                                        selectedGroup
                                                                            ? selectedGroup._id
                                                                            : undefined
                                                                    }
                                                                    columns={[
                                                                        {
                                                                            disableColumnMenu:
                                                                                true,
                                                                            sortable:
                                                                                false,
                                                                            field: "arrangedDate",
                                                                            headerName:
                                                                                "Fecha y hora",
                                                                            description:
                                                                                "Fecha y hora de envío",
                                                                            width: 150,
                                                                            renderCell:
                                                                                (
                                                                                    params
                                                                                ) => {
                                                                                    return (
                                                                                        moment(
                                                                                            new Date(
                                                                                                params.value
                                                                                            )
                                                                                        ).format(
                                                                                            "L"
                                                                                        ) +
                                                                                        " " +
                                                                                        moment(
                                                                                            new Date(
                                                                                                params.value
                                                                                            )
                                                                                        ).format(
                                                                                            "LT"
                                                                                        )
                                                                                    );
                                                                                },
                                                                        },
                                                                        {
                                                                            disableColumnMenu:
                                                                                true,
                                                                            sortable:
                                                                                false,
                                                                            field: "agentFirstName",
                                                                            headerName:
                                                                                "Vendedor",
                                                                            description:
                                                                                "Vendedor",
                                                                            width: 150,
                                                                            renderCell:
                                                                                (
                                                                                    params
                                                                                ) => {
                                                                                    return params.value
                                                                                        ? params.value +
                                                                                        " " +
                                                                                        params
                                                                                            .row
                                                                                            .agentLastName
                                                                                        : "";
                                                                                },
                                                                        },
                                                                        {
                                                                            disableColumnMenu:
                                                                                true,
                                                                            sortable:
                                                                                false,
                                                                            field: "visits",
                                                                            headerName:
                                                                                "Estado",
                                                                            description:
                                                                                "Estado",
                                                                            width: 125,
                                                                            renderCell:
                                                                                (
                                                                                    params
                                                                                ) => {
                                                                                    let title =
                                                                                        params.value &&
                                                                                            params
                                                                                                .value
                                                                                                .length >
                                                                                            0
                                                                                            ? params
                                                                                                .value[
                                                                                                params
                                                                                                    .value
                                                                                                    .length -
                                                                                                1
                                                                                            ]
                                                                                                .status
                                                                                            : "";
                                                                                    if (
                                                                                        title ==
                                                                                        "executed"
                                                                                    ) {
                                                                                        return "Realizada";
                                                                                    } else if (
                                                                                        title ==
                                                                                        "pending"
                                                                                    ) {
                                                                                        return "Pendiente";
                                                                                    } else {
                                                                                        return title;
                                                                                    }
                                                                                },
                                                                        },
                                                                        {
                                                                            disableColumnMenu:
                                                                                true,
                                                                            sortable:
                                                                                false,
                                                                            field: "createdAt",
                                                                            headerName:
                                                                                "Fecha creación",
                                                                            description:
                                                                                "Fecha y hora de creación",
                                                                            width: 150,
                                                                            renderCell:
                                                                                (
                                                                                    params
                                                                                ) => {
                                                                                    return (
                                                                                        moment(
                                                                                            new Date(
                                                                                                params.value
                                                                                            )
                                                                                        ).format(
                                                                                            "L"
                                                                                        ) +
                                                                                        " " +
                                                                                        moment(
                                                                                            new Date(
                                                                                                params.value
                                                                                            )
                                                                                        ).format(
                                                                                            "LT"
                                                                                        )
                                                                                    );
                                                                                },
                                                                        },
                                                                        {
                                                                            disableColumnMenu:
                                                                                true,
                                                                            sortable:
                                                                                false,
                                                                            field: "contactName",
                                                                            headerName:
                                                                                "Contacto",
                                                                            description:
                                                                                "Contacto",
                                                                            width: 150,
                                                                            renderCell:
                                                                                (
                                                                                    params
                                                                                ) => {
                                                                                    return (
                                                                                        params
                                                                                            .row
                                                                                            ?.prospect
                                                                                            ?.firstName +
                                                                                        " " +
                                                                                        params
                                                                                            .row
                                                                                            ?.prospect
                                                                                            ?.lastName
                                                                                    );
                                                                                },
                                                                        },

                                                                        {
                                                                            disableColumnMenu:
                                                                                true,
                                                                            sortable:
                                                                                false,
                                                                            field: "contactPhone",
                                                                            headerName:
                                                                                "Télefono",
                                                                            description:
                                                                                "Télefono",
                                                                            width: 150,
                                                                            renderCell:
                                                                                (
                                                                                    params
                                                                                ) => {
                                                                                    return params
                                                                                        .row
                                                                                        ?.prospect
                                                                                        ?.phones[0];
                                                                                },
                                                                        },
                                                                        {
                                                                            disableColumnMenu:
                                                                                true,
                                                                            sortable:
                                                                                false,
                                                                            field: "source",
                                                                            headerName:
                                                                                "Origen",
                                                                            description:
                                                                                "Origen",
                                                                            width: 150,
                                                                            renderCell:
                                                                                (
                                                                                    params
                                                                                ) => {
                                                                                    return params
                                                                                        .row
                                                                                        ?.prospect
                                                                                        ?.source[0];
                                                                                },
                                                                        },
                                                                    ]}
                                                                    icon={
                                                                        <TransferWithinAStation />
                                                                    }
                                                                    filters={[
                                                                        "Fecha",
                                                                    ]}
                                                                    title="Visitas"
                                                                    source="visits"
                                                                    dateField="arrangedDate"
                                                                    userId={
                                                                        selectedUser
                                                                            ? selectedUser._id
                                                                            : rol ==
                                                                                "super" ||
                                                                                rol ==
                                                                                "comercial"
                                                                                ? "all"
                                                                                : usuario._id
                                                                    }
                                                                />
                                                            </Grid>
                                                        )}
                                                </Grid>
                                            </Module>
                                        </Grid>
                                    </Grid>
                                );
                            case "reportlist":
                                return (
                                    <></>
                                );
                            case "tab":
                                // if(process.env.NODE_ENV == 'development' || getCompany() && (getCompany()._id == "62a2458a6032e8d9ea9c4559" || getCompany()._id == '62b31ae6bba0a9aa6ba1ac09' || getCompany()._id == '62b1dd35994a1321e3d6bd4c')){
                                return (
                                    <TabPanelNativa
                                        key={p._id}
                                        user={
                                            selectedUser
                                                ? selectedUser
                                                : selectedGroup
                                                    ? {
                                                        ...usuario,
                                                        group: selectedGroup,
                                                    }
                                                    : usuario
                                        }
                                    />
                                );
                            // }else{
                            //     return <TabPanel key={p._id} tabs={p.content.tabs} user={selectedUser ? selectedUser : (selectedGroup ? {...usuario, group: selectedGroup } : usuario)} />
                            // }

                            case "lastcall":
                                return ''
                            // return !selectedUser ? (
                            //     <LastCall key={p._id} />
                            // ) : (
                            //     <LastCall
                            //         key={p._id}
                            //         selectedUser={selectedUser}
                            //     />
                            // );
                            case "training":
                                return "";
                            //     return <TrainingPanelModule user={selectedUser ? selectedUser : usuario}/>
                            case "performanceGroup":
                                // return <LastCallGroup key={p._id} groupFilter={selectedGroup ? selectedGroup._id : undefined}/>
                                let user_usando_list = selectedUser
                                    ? selectedUser._id
                                    : rol == "super" || rol == "comercial"
                                        ? undefined
                                        : usuario._id;
                                let usuarios_a_mostrarlist = selectedGroup
                                    ? selectedGroup.users.map((u) => u._id)
                                    : user_usando_list;

                                return (
                                    <>
                                        <StatusCard
                                            key={p._id}
                                            user={usuarios_a_mostrarlist}
                                            groupFilter={
                                                selectedGroup
                                                    ? selectedGroup._id
                                                    : undefined
                                            }
                                        />
                                    </>
                                );
                            case "meta":
                                let user_usando = selectedUser
                                    ? selectedUser
                                    : rol == "super" || rol == "comercial"
                                        ? undefined
                                        : usuario;
                                let usuarios_a_mostrar = selectedGroup
                                    ? { ...usuario, group: selectedGroup }
                                    : user_usando;
                                // let usuario_con_grupo = selectedGroup ? {...usuario, group: selectedGroup } : true
                                return (
                                    <Module
                                        title="Metas establecidas"
                                        action={
                                            isAuth() && (isAuth().roles.includes('comercial') || isAuth().roles.includes('super')) ?
                                                <NextLink href={"/company/goals"}>
                                                    <ToggleButton
                                                        href="#"
                                                        value=""
                                                        sx={{
                                                            py: 0.25,
                                                            color: "#fff",
                                                            borderColor: "#0080ff",
                                                            background: "#0080ff",
                                                            "&:hover": {
                                                                background:
                                                                    "#0080ff",
                                                                color: "#fff",
                                                            },
                                                        }}
                                                    >
                                                        <CloudUpload
                                                            sx={{
                                                                mr: 1,
                                                            }}
                                                        />
                                                        Subir/Actualizar metas
                                                    </ToggleButton>
                                                </NextLink>
                                                : ''
                                        }
                                    >
                                        <Goal
                                            key={p._id}
                                            seller={!user_usando}
                                            group={!user_usando}
                                            user={usuarios_a_mostrar}
                                        />
                                    </Module>
                                );
                            case "performance":
                                let user_usando_per = selectedUser
                                    ? selectedUser._id
                                    : rol == "super" || rol == "comercial"
                                        ? undefined
                                        : usuario._id;
                                let usuarios_a_mostrar_per = selectedGroup
                                    ? selectedGroup.users.map((u) => u._id)
                                    : user_usando_per;

                                let alertaBillsUserP = _.get(getCompany(), 'alertPayYourBills')
                                let rolesUserP = _.get(isAuth(), 'roles', [])
                                let alertaBillsP = false
                                if (alertaBillsUserP) {
                                    if (rolesUserP.includes(alertaBillsUserP)) {
                                        alertaBillsP = true
                                    } else if (alertaBillsUserP == 'super' && rolesUserP.includes('comercial')) {
                                        alertaBillsP = true
                                    } else if (alertaBillsUserP == 'user') {
                                        alertaBillsP = true
                                    }
                                }
                                let alertaBillsSeveretyP = _.get(getCompany(), 'alertPayYourBillsSeverity', 'low')
                                return (
                                    <>
                                        {
                                            alertaBillsP && <Grid item mb={2}>
                                                <Alert
                                                    severity={alertaBillsSeveretyP == 'low' ? 'warning' : "error"}
                                                    sx={alertaBillsSeveretyP == 'high' ?
                                                        { background: '#F99', border: '2px solid #d66', color: 'dark', fontSize: '18px' }
                                                        : alertaBillsSeveretyP == 'medium' ?
                                                            {}
                                                            : {}
                                                    }
                                                    icon={<ErrorIcon sx={alertaBillsSeveretyP == 'high' ? { color: 'dark', fontSize: '34px' } : {}} />}
                                                >
                                                    <Linkify>
                                                        <b>Alerta de facturación: </b>
                                                        {_.get(getCompany(), 'alertPayYourBillsDescription',
                                                            'Consulta el estado de tus facturas y paga a tiempo para evitar penalizaciones y suspensión de tu servicio. Consultar con tu ejecutivo comercial.')}
                                                    </Linkify>
                                                </Alert>
                                            </Grid>
                                        }
                                        <Grid key={p._id} container rowSpacing={2} sx={{ width: '100%' }}>
                                            {typeof usuarios_a_mostrar_per != 'object' && (user_usando_per || _.get(isAuth(), 'roles', []).indexOf('comercial') < 0) &&
                                                <>
                                                    <Grid item xs={12}>
                                                        <Trophy
                                                            user={usuarios_a_mostrar_per}
                                                        />
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12}>
                                                <Performance
                                                key={p._id}
                                                user={usuarios_a_mostrar_per}
                                                selectedUserSyncHistory={selectedUser ?? ''}
                                            />
                                                {/* <Performance
                                                key={p._id}
                                                user={usuarios_a_mostrar_per}
                                                selectedUserSyncHistory={selectedUser ?? ''}
                                            /> */}
                                            </Grid>
                                        </Grid>
                                    </>
                                );
                            // case "performance":
                            //     return <Trophy />
                            default:
                                return (
                                    <Module title={p.title}>
                                        <p className="ml-3 mb-3">
                                            {JSON.stringify(p)}
                                        </p>
                                    </Module>
                                );
                        }
                    })
                    .filter((ele) => ele != "")
                    .map((ele) => (
                        <Grid item>{ele}</Grid>
                    ))}
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    users: state.main.users,
    selectedUser: state.main.selected_user,
    selectedGroup: state.main.selected_group,
    usarAyer: state.main.usarAyer,
});

const mapDispatchToProps = {
    setUsers: setUsers,
    setSelectedUser: setSelectedUser,
    setUsarAyer: setUsarAyer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
